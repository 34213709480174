import { useQuery, useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { productController } from "../../controllers/product-controller";
import { useSelector } from "react-redux";

export default function useProducts() {
  const { SEARCH_PRODUCTS } = QUERY_KEYS;
  const productReducer = useSelector((state) => state.productReducer);
  const queryClient = useQueryClient();

  const searchKeyword = productReducer.productSearch;
  const sortBy = productReducer.productSortBy;
  const productPage = productReducer.productPage;

  const getSearchResults = async (search, sort, page) => {
    const products = await productController.searchProducts(search, sort, page);
    return products.data;
  };

  const searchProducts = useQuery(
    [SEARCH_PRODUCTS, productPage, searchKeyword, sortBy],
    () => getSearchResults(searchKeyword, sortBy, productPage),
    {
      keepPreviousData: true,
      staleTime: 5000,
      refetchOnWindowFocus: false,
    }
  );

  const prefetchProducts = () => {
    queryClient.prefetchQuery(
      [SEARCH_PRODUCTS, productPage + 1, searchKeyword, sortBy],
      () => getSearchResults(searchKeyword, sortBy, productPage + 1)
    );
  };

  const getProductsTotalPages = () => {
    return searchProducts.data?.data?.totalPages;
  }

  return { prefetchProducts, searchProducts, getProductsTotalPages };
}
