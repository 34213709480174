import { getActivFlags } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const clickstreamController = {
    addActivity(activity) {
        let url = `${
            config[baseURLIdentifiers.USER_SERVICE]
          }/api/v1/user/activityAsync`;
        
        const flags = getActivFlags();
        
        if (flags["experiment_002"]) {
            url = `${
                config[baseURLIdentifiers.USER_SERVICE]
              }/api/v1/user/activitySync`;
        }

        fetcher.post(url, activity)
            .catch(err => {
                console.error(err);
            });            
    },

    getClickstreamPayload(action) {
        return {
            "eventTime": new Date().toISOString(),
            "version": 0,
            "pageUrl": document.location.href,
            "referer": document.referrer,
            "eventType": action,
            "element_id": "",
            "element_class": "",
            "attributes": [
                {
                    "key": "viewportWidth",
                    "value": window.innerWidth
                },
                {
                    "key": "viewportHeight",
                    "value": window.innerHeight
                },
                {
                    "key": "userAgent",
                    "value": navigator.userAgent
                },
                {
                    "key": "platform",
                    "value": navigator.userAgentData.platform ? navigator.userAgentData.platform : "not available"
                },
                {
                    "key": "isMobile",
                    "value": navigator.userAgentData.mobile
                }
            ]
        }
    },

    addAttributes(activity, ...attributes) {        
        if (attributes.length > 0)
        {
            activity.attributes = activity.attributes.concat(attributes);
        }
        return activity;
    }
};