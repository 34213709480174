import React from "react";

export default function InfoBubble({ info }) {
  return (
    <div
      className={`gap-2 text-sm py-2 px-3 rounded-full bg-zcGrey-200 font-medium text-zcGrey-900`}
    >
      {info}
    </div>
  );
}
