import React, { useEffect } from "react";
import useWebhook from "../../hooks/webhook/useWebhook";
import { Badge, Box, Button, Divider, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import NotFound from "../../components/Products/NotFound";

const WebhookPage = () => {
    const { whLogsQuery, prefetchWhLogs } = useWebhook();
    const navigate = useNavigate();

    useEffect(
        () => {
            prefetchWhLogs();
        },
        [prefetchWhLogs]
    );

    const whLogs = whLogsQuery?.data?.data?.data || [];

    const goToWebhookRegPage = () => {
        navigate(routes.WEBHOOK_REG);
    };

    if (!whLogs || whLogs.length === 0) {
        return (
            <NotFound
                buttonText="Register a webhook"
                onButtonClick={goToWebhookRegPage}
                message={<div>No logs found</div>}
            />
        )
    }
    
    return (
        <>
            <div>
                <Flex justify="space-between" alignItems="center">
                    <Heading size="lg" px={8}>
                        Webhook Logs
                    </Heading>
                    <Button onClick={goToWebhookRegPage}>
                        New Webhook
                    </Button>
                </Flex>
                
                <Divider my={6} />

                <Tabs variant='enclosed'>
                    <TabList>
                        {whLogs.map((wh, index) => (
                            <Tab key={index}>
                                {`${wh?.webhook?.callbackUrl
                                    .slice(
                                        wh?.webhook?.callbackUrl.indexOf('://')+3, 
                                        wh?.webhook?.callbackUrl.length < 25 ? wh?.webhook?.callbackUrl.length : 25)}...`}
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {whLogs.map((wh, index) => (
                            <TabPanel key={index}>
                                <Heading size="md">
                                    {wh?.webhook?.callbackUrl}
                                </Heading>
                                <Box p="6">
                                    <TableContainer>                                        
                                        <Table size='sm' variant="striped" colorScheme="zcGrey">
                                            <Thead>
                                                <Tr>
                                                    <Th>Trigger</Th>
                                                    <Th>Status</Th>
                                                    <Th>Sent At</Th>
                                                    <Th>Comment</Th>
                                                    <Th />
                                                </Tr>
                                            </Thead>
                                            <Tbody>                                                        
                                                {wh?.logs.map((log, index2) => (
                                                    <Tr>                  
                                                        <Td key={index*index2}>{log?.trigger}</Td>
                                                        <Td key={index*index2+1}>
                                                            <Badge colorScheme={log?.statusCode && log?.statusCode === 200 ? 'zcGreen' : 'zcAlert'}>
                                                                {log?.statusCode}
                                                            </Badge>                                                            
                                                        </Td>
                                                        <Td key={index*index2+2}>{log?.sentAt}</Td>
                                                        <Td key={index*index2+3}>
                                                            <p style={{textWrap: 'wrap'}}>{log?.comment}</p>
                                                        </Td>     
                                                        <Td />
                                                    </Tr>                                                   
                                                ))}                                                        
                                            </Tbody>
                                        </Table>                                                
                                    </TableContainer>
                                </Box>
                            </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>
            </div>
        </>
    )
}

export default WebhookPage;