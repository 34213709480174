import { Avatar } from "@chakra-ui/react";
import { format } from "date-fns";
import { capitalize } from "lodash";
import React from "react";
import StarRatings from "react-star-ratings";

export default function Review({ review, key }) {
  return (
    <div key={key} className="flex gap-4">
      <Avatar name={review.reviewerName} size="sm" />

      <div className="flex flex-col gap-3">
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <div className="font-medium">{capitalize(review.reviewerName)}</div>
            <div className="text-xs text-zcGrey-600">
              {format(new Date(), "do LLL yyy")}
            </div>
          </div>
          <StarRatings
            rating={review.rating}
            starRatedColor="#FDB022"
            starEmptyColor="#dedede"
            numberOfStars={5}
            starSpacing="2px"
            starDimension="16px"
          />
        </div>

        <div className="text-zcGrey-700">{review.message}</div>
      </div>
    </div>
  );
}
