import {
  SET_PRODUCT_PAGE,
  SET_PRODUCT_SEARCH,
  SET_PRODUCT_SORT_BY,
  SET_PRODUCT_PAGE_SIZE,
} from "../constants/types";

const initialState = {
  productSearch: "",
  productSortBy: "DATE_ADDED",
  productPage: 0,
  productPageSize: 2,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_SEARCH:
      return {
        ...state,
        productSearch: action.payload,
        productPage: 0,
      };

    case SET_PRODUCT_SORT_BY:
      return {
        ...state,
        productSortBy: action.payload,
      };

    case SET_PRODUCT_PAGE:
      return {
        ...state,
        productPage: action.payload,
      };

    case SET_PRODUCT_PAGE_SIZE:
      return {
        ...state,
        productPageSize: action.payload,
      };

    default:
      return state;
  }
};
