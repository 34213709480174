import React from "react";
import UpiApps from "./UpiApps";
import CustomOtpInput from "../CutomOtpInput";
import { Input } from "@chakra-ui/react";

export default function UpiMethod({ message, otp, setOtp, vpa, setVpa }) {
  const [selectedUPI, setSelectedUPI] = React.useState("GOOGLE_PAY");

  return (
    <div className="flex flex-col gap-4">
      <div className="text-sm">Select your UPI app</div>
      <UpiApps
        selectedUPI={selectedUPI}
        onUPICardClick={(app) => setSelectedUPI(app.id)}
      />
      <div className="flex flex-col w-full gap-4 justify-between">
        <div className="flex items-center gap-4">
          <div className="text-sm max-w-[80px] w-full">UPI Id</div>

          <Input
            value={vpa}
            onChange={(e) => setVpa(e.target.value)}
            name="vpa"
            type="email"
            placeholder="Virtual Payment Address"
            backgroundColor="#F5F5F5"
            borderRadius="6px"
            width="full"
            border="1px solid #3C3C3C"
            _focus={{boxShadow: "none"}}
          />
        </div>

        <div className="flex items-center gap-4">
          <div className="text-sm max-w-[80px] w-full">{`Enter OTP`}</div>
          <CustomOtpInput otp={otp} setOtp={setOtp} />
        </div>

        <div className="text-sm">
          <span className="font-bold">DISCLAIMER: </span>
          <span>
            All entered payment information is simulated and not stored or used
            anywhere else in Crio.Do. Please do not enter any real payment
            information.
          </span>
        </div>

        {message && (
          <div className="text-sm text-brand-700 font-medium">{message}</div>
        )}
      </div>
    </div>
  );
}
