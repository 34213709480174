import React, { useCallback, useRef } from "react";
import useRecommendedProducts from "../../hooks/products/useRecommendedProducts";
import Product from "../Products/Product";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Skeleton, useMediaQuery } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import routes from "../../constants/routes";
import { clickstreamController } from "../../controllers/clickstream-controller";

export default function RecommendedProducts() {
  const recommendedProductsHook = useRecommendedProducts();
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false, 
  })

  const recommendedProducts = recommendedProductsHook?.data?.data?.content;
  const shouldShowRecommendedProducts = recommendedProducts?.length > 0;
  const isRecommendedProductsLoading = recommendedProductsHook.isLoading;

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    // send clickstream data
    let activity = clickstreamController.getClickstreamPayload("click");
    activity.element_class = "cursor-pointer";
    activity = clickstreamController.addAttributes(activity,
      {"key": "section", "value": "recommended products"},
      {"key": "action", "value": "prev arrow click"}
    );
    clickstreamController.addActivity(activity);
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    // send clickstream data
    let activity = clickstreamController.getClickstreamPayload("click");
    activity.element_class = "cursor-pointer";
    activity = clickstreamController.addAttributes(activity, 
      {"key": "section", "value": "recommended products"},
      {"key": "action", "value": "next arrow click"}
    );
    clickstreamController.addActivity(activity);
  }, []);

  const onProductClick = (product) => {
    // send clickstream data
    let activity = clickstreamController.getClickstreamPayload("click");
    activity.element_class = "flex flex-col gap-2 min-w-[200px] max-w-[350px] cursor-pointer";
    activity = clickstreamController.addAttributes(activity, 
      {"key": "section", "value": "recommended products"},
      {"key": "action", "value": "product click"},
      {"key": "product.id", "value": product.id},
      {"key": "product.updatedAt", "value": product.updatedAt},
      {"key": "product.version", "value": product.version}
    );
    clickstreamController.addActivity(activity);

    navigate(`${routes.PRODUCT}/${product.id}`);
  };

  if (isRecommendedProductsLoading)
    return (
      <div className="pt-10 w-full h-full mb-10 px-4 rounded-xl">
        <Skeleton width={"full"} h="200px" />
      </div>
    );

  if (!shouldShowRecommendedProducts) return <></>;

  return (
    <div className={`w-full h-full flex flex-col ${isLargerThan800 ? "py-10" : "pb-10"} gap-4`}>
      <div className="font-bold text-xl">Recommended Products</div>
      <div className="flex items-center gap-3">
        <FontAwesomeIcon
          icon="chevron-left"
          onClick={handlePrev}
          className="cursor-pointer"
        />

        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          className="h-full w-full"
          ref={sliderRef}
        >
          {recommendedProducts?.map((product) => (
            <SwiperSlide
              key={`recommended-product-${product.id}`}
              className="max-w-[320px]"
            >
              <Product product={product} onProductClick={onProductClick} />
            </SwiperSlide>
          ))}
        </Swiper>
        <FontAwesomeIcon
          icon="chevron-right"
          onClick={handleNext}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
}
