import React, { useEffect } from "react";
import HeadingText from "../HeadingText";
import ProductSort from "./ProductsSort";
import Product from "./Product";
import ProductsPagination from "./ProductsPagination";
import ProductsLoader from "./ProductsLoader";
import useProducts from "../../hooks/products/useProducts";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "./NotFound";
import { useDispatch } from "react-redux";
import { SET_PRODUCT_SEARCH } from "../../constants/types";
import routes from "../../constants/routes";

export default function Products() {
  const { searchProducts: productsHook, prefetchProducts } = useProducts();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const products = productsHook.data?.data?.content;
  const productReducer = useSelector((state) => state.productReducer);
  const searchKeyword = productReducer.productSearch;

  useEffect(() => {
    const data = productsHook?.data?.data;

    if (data) {
      if (data.number + 1 <= data.totalPages - 1) {
        prefetchProducts();
      }
    }
  }, [productsHook.data]);

  const onProductClick = (product) => {
    navigate(`${routes.PRODUCT}/${product.id}`);
  };

  const getProductsUI = () => {
    if (productsHook.isLoading) return <ProductsLoader />;

    if (!products?.length)
      return (
        <NotFound
          // hideButton={searchKeyword ? false : true}
          hideButton
          buttonText={"Clear Search"}
          onButtonClick={() => {
            dispatch({
              type: SET_PRODUCT_SEARCH,
              payload: "",
            });
          }}
          message={
            searchKeyword ? (
              <div>
                <span>We could not find any matches for</span>
                <span className="font-bold">&nbsp;"{`${searchKeyword}`}"</span>
              </div>
            ) : (
              <div>No Products found</div>
            )
          }
        />
      );

    return (
      <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 gap-6">
        {products?.map((product) => (
          <Product
            key={`product-${product.id}`}
            product={product}
            onProductClick={onProductClick}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={`w-full h-full pb-10 flex flex-col gap-4`}>
      <div className="flex justify-between">
        <HeadingText text={searchKeyword ? "Search Results" : "All Products"} />
        {products?.length ? <ProductSort /> : <></>}
      </div>

      <div className="w-full h-full">{getProductsUI()}</div>

      {products?.length ? (
        <div className="flex items-center justify-center">
          <ProductsPagination />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
