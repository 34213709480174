import React, { useState } from "react";
import OTPInput from "react-otp-input";
import CustomOtpInput from "../CutomOtpInput";

export default function CardMethod({ message, otp, setOtp }) {
  return (
    <div className="flex flex-col gap-4">
      <div className="text-md">Enter OTP</div>
      <div className="flex flex-col w-full gap-4 justify-between">
        <CustomOtpInput otp={otp} setOtp={setOtp} />

        <div className="text-sm">
          <span className="font-bold">DISCLAIMER: </span>
          <span>
            All entered payment information is simulated and not stored or used
            anywhere else in Crio.Do. Please do not enter any real payment
            information.
          </span>
        </div>

        {message && (
          <div className="text-sm text-brand-700 font-medium">{message}</div>
        )}
      </div>
    </div>
  );
}
