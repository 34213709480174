import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import { SET_USER_DATA } from "../constants/types";

const AuthContext = createContext();

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID,
  ClientId: process.env.REACT_APP_APPCLIENT_ID,
});

const Auth = (props) => {
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const signup = async (email, password, name) => {
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: name,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: "-",
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: "-",
      }),
      new CognitoUserAttribute({
        Name: "gender",
        Value: "-",
      }),
      new CognitoUserAttribute({
        Name: "birthdate",
        Value: "24/11/2000",
      }),
    ];

    return await new Promise((resolve, reject) => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        }

        resolve(result);
      });
    });
  };

  const authenticate = async (email, password) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

  const getSession = async (email, password) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            setIsLoggedIn(false);
            refreshSession();
            reject(err);
          } else {
            dispatch({
              type: SET_USER_DATA,
              payload: session.idToken.payload,
            });
            localStorage.setItem("token", session.accessToken.jwtToken);

            localStorage.setItem(
              "refreshToken",
              JSON.stringify(session.refreshToken.token)
            );

            setIsLoggedIn(true);
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const refreshSession = () => {
    const cognitoUser = userPool.getCurrentUser();

    let token = new CognitoRefreshToken({ RefreshToken: refreshToken });

    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        return;
      }
    });
  };

  const logout = () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut();
      setIsLoggedIn(false);
      localStorage.setItem("token", "");
    }
  };

  return (
    <AuthContext.Provider
      value={{ signup, authenticate, getSession, logout, isLoggedIn }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { Auth, AuthContext };
