import { Button } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../constants/routes";

export default function Thanks() {
  const params = useParams();
  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.authReducer);

  const email = userData?.email;

  const routeToProducts = () => {
    navigate(routes.HOME);
  };

  const routeToOrderHistory = () => {
    navigate(routes.ORDER_HISTORY);
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="font-bold text-xl">
          Your order is successfully placed
        </div>
        <div>
          <span>Order No:&nbsp;</span>
          <span className="font-bold">{params.id}</span>
        </div>
        <div>
          <span>
            A copy of your order summary has been emailed to you at&nbsp;
          </span>
          <span className="font-bold">{email}</span>
        </div>

        <div className="flex gap-4">
          <Button
            colorScheme="brand"
            rounded="full"
            height="50px"
            padding="8px 32px"
            onClick={routeToOrderHistory}
          >
            View Orders
          </Button>
          <Button
            colorScheme="brand"
            rounded="full"
            variant="outline"
            height="50px"
            padding="8px 32px"
            onClick={routeToProducts}
          >
            Continue Shopping
          </Button>
        </div>
      </div>
    </div>
  );
}
