import Home from "./components/Home/Home";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ProductDetail from "./components/ProductDetail";
import CartPage from "./pages/CartPage";
import routes from "./constants/routes";
import Checkout from "./components/Checkout";
import { Route, Routes } from "react-router-dom";
import Thanks from "./components/Thanks/Thanks";
import OrderHistory from "./components/Orders/OrderHistory";
import { useEffect } from "react";
import useAuthHandler from "./hooks/utility/useAuthHandler";
import PageNotFound from "./pages/PageNotFound";
import WebhookRegistrationPage from "./pages/WebhookRegistrationPage";
import WebhookPage from "./pages/WebhookPage";

function App() {
  const { verifyLoginForPageRedirect } = useAuthHandler();

  useEffect(() => {
    verifyLoginForPageRedirect(() => {});
  }, [verifyLoginForPageRedirect]);

  return (
    <Routes>
      <Route exact path={routes.HOME} element={<Home />} />
      <Route exact path={routes.LOGIN} element={<LoginPage />} />
      <Route exact path={routes.WEBHOOK_REG} element={<WebhookRegistrationPage />} />
      <Route exact path={routes.WEBHOOK_LOGS} element={<WebhookPage />} />
      <Route exact path={routes.SIGNUP} element={<RegisterPage />} />
      <Route
        exact
        path={routes.FORGOT_PASSWORD}
        element={<ForgotPasswordPage />}
      />
      <Route path={`${routes.PRODUCT}/:id`} element={<ProductDetail />} />
      <Route path={routes.CART} element={<CartPage />} />
      <Route exact path={routes.CHECKOUT} element={<Checkout />} />
      <Route exact path={routes.ORDER_HISTORY} element={<OrderHistory />} />
      <Route exact path={routes.THANKS + `/:id`} element={<Thanks />} />
      <Route path="*" element={<PageNotFound/>}/>
    </Routes>
  );
}

export default App;
