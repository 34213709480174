export const productSortKeys = [
  "DATE_ADDED",
  "AVAILABILITY",
  "DISCOUNT",
  "BEST_SELLER",
  "PRICE_HIGH_TO_LOW",
  "PRICE_LOW_TO_HIGH",
];

export const EPaymentType = {
  CASH_ON_DELIVERY: "CASH_ON_DELIVERY",
  DEBIT_CARD: "DEBIT_CARD",
  CREDIT_CARD: "CREDIT_CARD",
  UPI: "UPI",
  NET_BANKING: "NET_BANKING",
};
