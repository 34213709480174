import { Button } from "@chakra-ui/react";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormField from "../../components/FormField";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID,
  ClientId: process.env.REACT_APP_APPCLIENT_ID,
});

const ELoginFormField = {
  email: "email",
  password: "password",
};

const ForgotPasswordPage = () => {
  const navigate =useNavigate();
  const dispatch = useDispatch();

  // const [stage, setStage] = useState(null);

  const authReducer = useSelector((state) => state.authReducer);

  const getUser = () => {
    return new CognitoUser({
      Username: watch()[ELoginFormField.email],
      Pool: userPool,
    });
  };

  // let stage = 0;

  const onResetPassword = (values) => {
    getUser().forgotPassword({
      onSuccess: (result) => {
        console.log("call result: " + result);
      },
      onFailure: (err) => {
        alert(err);
      },
      inputVerificationCode: (data) => {
        console.log("TEST " + JSON.stringify(data));
        localStorage.setItem("stage", "1");
      },
    });
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      verificationCode: "",
      password: "",
    },
  });

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <form
          className="shadow-md p-4 rounded-lg flex flex-col gap-2 justify-cetner"
          onSubmit={handleSubmit(onResetPassword)}
        >
          <h1 className="text-xl font-bold text-center">Sign In</h1>

          <FormField
            name={ELoginFormField.email}
            errors={errors}
            type="email"
            label="Email Address"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 4, message: "Minimum length should be 4" },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            className="w-full md:min-w-[350px]"
          />

          <>
            <FormField
              name={ELoginFormField.verificationCode}
              errors={errors}
              type="text"
              label="Enter verification code"
              register={register}
              className="w-full md:min-w-[350px]"
            />

            {/* <FormField
                name={ELoginFormField.password}
                errors={errors}
                type="password"
                label="New Password"
                register={register}
                className="w-full md:min-w-[350px]"
              /> */}
          </>
          <Button
            mt={4}
            // isLoading={isSubmitting}
            type="submit"
            _focus={{ boxShadow: "none" }}
            colorScheme="brand"
            className="mt-4"
          >
            Reset Password
          </Button>
          {/* <div className="flex justify-between text-sm">
            <Link to={"/signup"} className="underline text-blue-500">
              Register
            </Link>
            <Link to={"/forgot-password"} className="underline text-blue-500">
              Forgot Password
            </Link>
          </div> */}
        </form>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
