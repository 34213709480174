import React from "react";
import Gpay from "../../../../../assets/icons/gpay.png";
import PhonePe from "../../../../../assets/icons/phonepe.png";
import Cred from "../../../../../assets/icons/cred.jpg";
import Bhim from "../../../../../assets/icons/bhim.png";

const UPI_APPS = [
  {
    id: "GOOGLE_PAY",
    name: "Google Pay",
    img: Gpay,
  },
  {
    id: "PHONE_PE",
    name: "PhonePe",
    img: PhonePe,
  },
  {
    id: "CRED_UPI",
    name: "Cred UPI",
    img: Cred,
  },
  {
    id: "BHIM_UPI",
    name: "Bhim UPI",
    img: Bhim,
  },
];

export default function UpiApps({ selectedUPI, onUPICardClick }) {
  const getCard = (app, selected) => {
    return (
      <div
        key={app.id}
        onClick={() => {onUPICardClick(app)}}
        className={`flex items-center gap-3 p-2 cursor-pointer rounded ${
          selected ? "bg-blue-100 border border-blue-300" : "bg-zcGrey-100 border border-zcGrey-300"
        }`}
      >
        <div className="w-10 h-10 rounded-md">
          <img src={app.img} alt="" className="w-full h-full rounded-md" />
        </div>
        <div className="font-medium text-sm flex-1">{app.name}</div>
      </div>
    );
  };

  return (
    <div className="grid sm:grid-cols-1 gap-2 md:grid-cols-2">
      {UPI_APPS.map((app) => {
        return getCard(app, selectedUPI === app.id);
      })}
    </div>
  );
}
