import React from "react";

const Footer = () => {
  return (
    <div className="bg-[#042C22] flex md:flex-row gap-10 flex-col py-8 px-4 md:px-14 w-full md:justify-between md:items-end">
      <div className="flex flex-col gap-4">
        <div className="w-[80px]">
          <img src="/logo_dark.png" alt="QKart-icon"></img>
        </div>

        <p className="text-left text-white opacity-50 text-base">
          ZCommerce is your one stop solution to
          <br />
          the buy the latest trending items with India's
          <br />
          Fastest Delivery to your doorstep
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <p className="md:text-right text-white opacity-50 text-base">
          175 & 176, Bannerghatta Main Rd,
          <br />
          Dollars Colony, Phase 4, J. P. Nagar,
          <br />
          Bengaluru, Karnataka 560076
        </p>
      </div>
    </div>
  );
};

export default Footer;
