import {
    Checkbox,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Stack
  } from "@chakra-ui/react";
  import React, { useState } from "react";

  export default function FormFieldCheckbox({
    name,
    validations,
    register,
    options,
    label,
    errors,
    className,
    errorMessageProps,
    onChange
  }) {
    const [checkedItems, setCheckedItems] = useState(
        options.map(() => false)
    )

    const handleCheckboxChange = (index) => (e) => {
        setCheckedItems([
            ...checkedItems.slice(0, index),
            e.target.checked,
            ...checkedItems.slice(index + 1)
        ]);
        if (onChange) {
            onChange(e);
        }        
    }

    return (
        <FormControl isInvalid={errors[name]} className={className}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <Stack spacing={5} direction={['column', 'row']}>
                {options.map((option, index) => (
                    <Checkbox 
                        colorScheme="brand"
                        key={index}
                        {...register(name, validations)}
                        _focus={{ boxShadow: "none" }}                    
                        value={option}                  
                        onChange={handleCheckboxChange(index)}
                        isChecked={checkedItems[index]}
                    >
                        {option}
                    </Checkbox>
                ))}
            </Stack>
            <FormErrorMessage {...errorMessageProps}>
                {errors[name] && errors[name].message}
            </FormErrorMessage>
        </FormControl>
    )
  };