import { IconButton } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

export default function ProductQuantity({
  onIncrease,
  onDecrease,
  value,
  setValue
}) {

  const handleIncrease = () => {
    const updatedQuantity = value + 1;

    setValue(updatedQuantity);

    if (onIncrease) onIncrease(updatedQuantity);
  };

  const handleDecrease = () => {
    const updatedQuantity = value === 0 ? 0 : value - 1;

    setValue(updatedQuantity);
    if (onDecrease) onDecrease(updatedQuantity);
  };

  return (
    <div className="flex gap-3 items-center rounded-full font-bold bg-zcGrey-300 px-3 py-2 text-brand-500">
      <IconButton
        borderRadius="full"
        onClick={handleDecrease}
        size="sm"
        bgColor={"transparent"}
      >
        <FontAwesomeIcon icon="minus" className="text-brand-200" />
      </IconButton>
      <div className="flex-1 w-[40px] text-center">{value}</div>
      <IconButton
        borderRadius="full"
        onClick={handleIncrease}
        size="sm"
        bgColor={"transparent"}
      >
        <FontAwesomeIcon icon="plus" className="text-brand-200" />
      </IconButton>
    </div>
  );
}
