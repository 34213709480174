import { useMediaQuery } from "@chakra-ui/react";
import React from "react";
import ReactPaginate from "react-paginate";

export default function Pagination({
  handlePageClick,
  totalPages,
  size,
  pageRangeDisplayed,
}) {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)", {
    ssr: true,
    fallback: false,
  });

  const getSize = () => {
    switch (size) {
      case "sm":
        return "md:h-6 md:w-6 w-5 h-5 text-xs";
      default:
        return "md:h-10 md:w-10 w-9 h-9";
    }
  };

  const getClass = () => {
    return `cursor-pointer border border-zcGrey-300 rounded-full flex items-center justify-center font-bold ${getSize()}`;
  };

  return (
    <ReactPaginate
      previousLabel={<div className={getClass()}>{"<"}</div>}
      pageLabelBuilder={(page) => <div className={getClass()}>{page}</div>}
      breakLabel="..."
      nextLabel={<div className={getClass()}>{">"}</div>}
      onPageChange={handlePageClick}
      pageRangeDisplayed={1}
      pageCount={totalPages}
      renderOnZeroPageCount={null}
      containerClassName="flex gap-2 flex-wrap"
      marginPagesDisplayed={isLargerThan800 ? 2 : 1}
      activeClassName="bg-brand-500 text-white"
      disabledClassName="text-zcGrey-500"
      pageClassName={getClass()}
    />
  );
}
