import React from "react";
import BannerCarousel from "../BannerCarousel";
import Products from "../Products";
import RecommendedProducts from "../RecommendedProducts";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@chakra-ui/react";

const Home = () => {
  const productReducer = useSelector((state) => state.productReducer);
  const searchKeyword = productReducer.productSearch;
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false,
  })

  return (
    <div className="flex flex-col h-full">
      {!searchKeyword && isLargerThan800 && <BannerCarousel />}
      {!searchKeyword && <RecommendedProducts />}
      <Products />
    </div>
  );
};

export default Home;
