import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Orders from "../../components/Orders";
import OrderSummaryCard from "../../components/OrderSummaryCard";
import routes from "../../constants/routes";
import useCart from "../../hooks/useCart";

const CartPage = () => {
  const navigate = useNavigate();

  const { cartQuery, cartData } = useCart();

  const availableItem = cartData?.data.availableItem;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return !cartQuery.isSuccess ? (
    <div className="h-full w-full flex justify-center items-center font-bold text-xl">
      No cart found!
    </div>
  ) : (
    <div className="h-full">
      <h2 className="text-black text-2xl font-semibold">Shopping cart</h2>
      <div className="flex lg:flex-row flex-col h-full pb-20">
        <Orders items={availableItem} />
        <OrderSummaryCard
          items={availableItem}
          onClick={() => {
            navigate(routes.CHECKOUT);
          }}
          buttonText={"Proceed to buy"}
          isDisabled={availableItem?.length === 0}
        />
      </div>
    </div>
  );
};

export default CartPage;
