import React from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import OrderCard from "../../OrderCard";
import AlertChip from "../../AlertChip";

export default function CartCheckout({ items }) {
  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <div>
          <AccordionButton
            padding="24px 0"
            _hover="none"
            _focus={{ boxShadow: "none" }}
          >
            <div className={`flex gap-4 w-full md:flex-row flex-col ${items?.length === 0 && "justify-between"}`}>
              <div className="font-bold text-left">
                <span>2.&nbsp;</span>
                <span className="underline">Review Products</span>
              </div>

              {
                items?.length === 0 && (
                  <AlertChip type="error" message="No products added to cart"/>
                )
              }

              {items?.length > 0 && (
                <AlertChip
                  message={
                    <div className="">
                      <span>Total&nbsp;</span>
                      <span className="font-bold">{`${items.length} items`}</span>
                      <span>&nbsp;in your cart</span>
                    </div>
                  }
                />
              )}
            </div>
          </AccordionButton>
          <AccordionPanel>
            <div className="font-semibold px-3 py-2 text-xs">
              {items?.map((data, idx) => (
                <div key={data.item.itemId}>
                  <OrderCard quantity={data.quantity} item={data.item} />
                  {idx === items.length - 1 ? (
                    <></>
                  ) : (
                    <div className="w-full h-[1px] bg-gray-200 my-4"></div>
                  )}
                </div>
              ))}
            </div>
          </AccordionPanel>
        </div>
      )}
    </AccordionItem>
  );
}
