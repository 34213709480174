import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useMediaQuery,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../helpers/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import routes from "../../constants/routes";
import { SET_PRODUCT_SEARCH } from "../../constants/types";
import useCart from "../../hooks/useCart";

const Header = ({ centerElement }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { logout, isLoggedIn } = useContext(AuthContext);

  const handleIconClick = () => {
    dispatch({
      type: SET_PRODUCT_SEARCH,
      payload: "",
    });
    if (location.pathname !== routes.HOME) {
      navigate(routes.HOME);
    }
  };

  return (
    <div className="h-[70px] py-4 px-2 md:px-14 border-b gap-3 border-gray-200 flex items-center fixed top-0 z-20 bg-white w-full">
      <div
        className="w-[60px] md:w-[150px] h-[30px] md:h-[50px] min-w-[35px] cursor-pointer"
        onClick={handleIconClick}
      >
        <img
          className="w-full h-full object-contain"
          src="/logo_light.png"
          alt="ZCommerce-icon"
        ></img>
      </div>
      <div className="flex items-center w-full justify-center">
        {centerElement}
      </div>

      <div className="ml-auto">
        {isLoggedIn ? (
          <ProfileMenu logout={logout} />
        ) : (
          <Button
            _focus={{ boxShadow: "none" }}
            colorScheme="brand"
            onClick={() => {
              navigate(routes.LOGIN);
            }}
          >
            Login
          </Button>
        )}
      </div>
    </div>
  );
};

const ProfileMenu = ({ logout }) => {
  const { cartData } = useCart();
  const cartDataObject = cartData?.data;
  const cartDataLength = cartDataObject?.availableItem?.length;

  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.authReducer);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)", {
    ssr: true,
    fallback: false,
  });

  const logoutHandler = () => {
    logout();
    navigate(routes.HOME);
  };

  return (
    <div className="flex items-center justify-center">
      {isLargerThan800 && (
        <div className="h-[40px] w-[40px] flex justify-center items-center mr-2 relative">
          <Link to={routes.CART}>
            <FontAwesomeIcon
              icon="cart-shopping"
              className="cursor-pointer text-emerald-600 text-2xl font-black mt-1"
            />
          </Link>
          {cartDataLength > 0 && (
            <div className="absolute -top-[2px] -right-[2px] bg-zcGrey-800 border border-zcGrey-900 h-5 p-1 w-5 rounded-full flex items-center justify-center">
              <div className="text-xs font-bold text-white">
                {cartDataLength > 9 ? "9+" : cartDataLength}
              </div>
            </div>
          )}
        </div>
      )}
      <Popover>
        <PopoverTrigger>
          <Button variant="unstyled">
            <div className={`flex items-center justify-center gap-2`}>
              <div className="rounded-full bg-red-100 md:h-[40px] md:w-[40px] w-7 h-7 flex justify-center items-center font-bold text-red-600 text-sm md:text-xl">
                {userData.name[0]}
              </div>
              {isLargerThan800 && (
                <div className="text-sm font-normal">{userData.name}</div>
              )}
              {isLargerThan800 && (
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="text-zcGrey-800 text-[14px] rotate-90"
                />
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          _focus={{ boxShadow: "none" }}
          className="w-full max-w-[200px] p-2 py-4 mr-4"
        >
          <PopoverBody className="flex flex-col gap-2">
            {!isLargerThan800 && (
              <Link to={routes.CART}>
                <h3 className="text-emerald-600 text-lg font-semibold">Cart</h3>
              </Link>
            )}

            <Link to={routes.ORDER_HISTORY}>
              <h3 className="text-emerald-600 text-lg font-semibold">
                Order History
              </h3>
            </Link>

            <h3
              className="text-red-400 text-lg font-semibold cursor-pointer"
              onClick={logoutHandler}
            >
              Logout
            </h3>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Header;
