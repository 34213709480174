import React, { useState } from "react";
import fallBackImage from "../../assets/images/fallback-image.webp";

export default function ImageComponent(props) {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <img
      {...props}
      src={imageError ? fallBackImage : props.image}
      alt=""
      onError={handleImageError}
    />
  );
}
