import React from "react";

export default function CustomBanner({
  title,
  image,
  onClick,
  backgroundColor,
  textColor,
  ...rest
}) {
  return (
    <div className={`w-full h-[300px] cursor-pointer px-20 flex relative items-center ${backgroundColor}`} onClick={onClick}>
      <div className={`text-4xl font-bold ${textColor}`} dangerouslySetInnerHTML={{__html: title}}></div>
      <div className="h-[200px] w-[400px] absolute right-20 ">
        <img src={image} alt="" />
      </div>
    </div>
  );
}
