import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function RatingCard({ rating }) {
  const getColor = () => {
    if (rating > 4) {
      return "text-brand-500 bg-brand-100";
    } else if (rating > 3) {
      return "text-zcYellow-200 bg-zcYellow-100";
    } else {
      return "text-zcAlert-300 bg-zcAlert-100";
    }
  };

  return (
    <div
      className={`flex items-center font-medium gap-2 text-sm py-2 px-3 rounded-full ${getColor()}`}
    >
      <FontAwesomeIcon icon="star" size="sm"/>
      <div>{rating}</div>
    </div>
  );
}
