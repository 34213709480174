import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import OrderCard from "../OrderCard";
import NotFound from "../Products/NotFound";

const Orders = ({ items }) => {
  const navigate = useNavigate();

  return (
    <div className="lg:w-[75%] flex flex-col lg:pr-8 h-full">
      {items.length === 0 && (
        <div className="h-full">
          <NotFound
            message="No items added to cart"
            onButtonClick={() => navigate(routes.HOME)}
          />
        </div>
      )}

      {items.map((data, idx) => (
        <div key={data.item.itemId}>
          {idx === 0 ? (
            <div className="w-full h-[1px] bg-gray-200 my-4"></div>
          ) : (
            <></>
          )}
          <OrderCard
            quantity={data.quantity}
            item={data.item}
            key={data.item?.id}
          />
          <div className="w-full h-[1px] bg-gray-200 my-4"></div>
        </div>
      ))}
    </div>
  );
};

export default Orders;
