import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { EPaymentType } from "../../../constants/product";
import CardMethod from "./CardMethod";
import UpiMethod from "./UpiMethod";
import useCreatePayment from "../../../hooks/payment/useCreatePayment";
import usePatchPayment from "../../../hooks/payment/usePatchPayment";
import { useNavigate } from "react-router-dom";
import routes from "../../../constants/routes";

export default function PaymentModal({
  onClose,
  isOpen,
  paymentType,
  orderId,
}) {
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [vpa, setVpa] = useState("");
  const navigate = useNavigate();

  const createPayment = useCreatePayment();
  const patchPayment = usePatchPayment();

  const isButtonDisabled = () => {
    if (isLoading) return true;
    if (paymentType === EPaymentType.UPI && (!vpa || !otp)) return true;
    return !otp;
  };

  useEffect(() => {
    setMessage("");
    setOtp("");
  }, [isOpen]);

  const getModalBody = () => {
    switch (paymentType) {
      case EPaymentType.UPI:
        return (
          <UpiMethod
            message={message}
            otp={otp}
            setOtp={setOtp}
            vpa={vpa}
            setVpa={setVpa}
          />
        );
      case EPaymentType.DEBIT_CARD:
        return <CardMethod message={message} otp={otp} setOtp={setOtp} />;
      default:
        return <></>;
    }
  };

  const payNow = async () => {
    setLoading(true);
    try {
      setMessage("Connecting to payment gateway...");

      const paymentResponse = await createPayment.mutateAsync({
        orderId,
        paymentType,
      });
      setMessage("Mimicking credentials...");

      const paymentId = paymentResponse?.data?.id;
      await patchPayment.mutateAsync(paymentId);
      setMessage("Payment successful!");

      setMessage("");
      setLoading(false);
      onClose();

      navigate(routes.THANKS + `/${orderId}`);
    } catch (error) {
      setMessage("Payment failed!");
      setLoading(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={onClose}
      size="lg"
      isOpen={isOpen}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Complete Payment</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>{getModalBody()}</ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            borderRadius="full"
            height="40px"
            paddingX="20px"
            isLoading={isLoading}
            onClick={payNow}
            disabled={isButtonDisabled()}
          >
            Pay Now
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
