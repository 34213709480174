import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import useProduct from "../../hooks/products/useProduct";
import { Button, Divider, IconButton, useToast } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsLoader from "../Products/ProductsLoader";
import ProductImageCarousel from "./ProductImageCarousel";
import { useNavigate, useLocation } from "react-router-dom";
import copy from "copy-to-clipboard";
import useCart from "../../hooks/useCart";
import routes from "../../constants/routes";
import useAuthHandler from "../../hooks/utility/useAuthHandler";
import ProductReviews from "./ProductReviews";
import RatingCard from "../RatingCard";
import InfoBubble from "../InfoBubble";

export default function ProductDetail() {
  const { id: productId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const productHook = useProduct(productId);
  const { verifyLoginAndProceed } = useAuthHandler();

  const { updateCartAndInvalidateCache, cartContainsItem } = useCart();

  const isLoading = productHook.isLoading;
  const productData = productHook?.data?.data || {};
  const isItemInCart = cartContainsItem(productId);

  function handleBackPress() {
    const lastPath = location.state?.from;

    if (lastPath === routes.HOME) {
      navigate.goBack();
    } else {
      navigate(routes.HOME);
    }
  }

  const shouldShowReviewsComponent = productData?.numberOfReviews > 0;

  const shouldShowImageComponent =
    Array.isArray(productData?.imageUrls) && productData?.imageUrls?.length > 0;

  const getActualPrice = useCallback((price, discount) => {
    const discountedPrice = price - (price * discount) / 100;
    return discountedPrice.toFixed(2);
  }, []);

  const getCurrentURL = useCallback(() => {
    const currentURL = `${window.location.origin}${location.pathname}${location.search}`;
    return currentURL;
  }, [location]);

  const onButtonClick = () => {
    if (isItemInCart) {
      verifyLoginAndProceed(() => navigate(routes.CART))
      return;
    }
    verifyLoginAndProceed(() =>
      updateCartAndInvalidateCache(productData.id, 1)
    );
  }

  const copyCurrentURL = () => {
    const currentURL = getCurrentURL();
    copy(currentURL);
    toast({
      description: <div>URL copied to clip-board</div>,
      status: "success",
      duration: 2000,
    });
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <Button
        width="min-content"
        variant="ghost"
        className="text-brand-300 hover:bg-transparent"
        fontSize={"14px"}
        _active={{
          bg: "transparent",
        }}
        padding={0}
        leftIcon={
          <FontAwesomeIcon
            icon="chevron-left"
            className="hover:bg-transparent"
          />
        }
        colorScheme="brand"
        onClick={handleBackPress}
      >
        Back to Home
      </Button>

      {isLoading ? (
        <ProductsLoader />
      ) : (
        <div className="flex md:flex-row flex-col h-full pb-10 gap-10 lg:gap-20">
          <div className="flex flex-col gap-4 max-w-[400px] lg:max-w-[450px]">
            {shouldShowImageComponent && (
              <ProductImageCarousel images={productData.imageUrls} />
            )}
          </div>

          <div className="h-full flex w-full max-w-[700px] flex-col gap-4">
            <div className="flex items-start justify-between gap-4">
              <div className="font-bold text-xl md:text-2xl">
                {productData.title}
              </div>
              <IconButton backgroundColor="#45C09F4F" onClick={copyCurrentURL}>
                <FontAwesomeIcon
                  icon="share-from-square"
                  className="text-brand-300"
                />
              </IconButton>
            </div>

            <Divider />
            <div className="flex gap-5 items-start">
              <div className="flex flex-col gap-1">
                <div className="font-bold text-lg">{`₹${getActualPrice(
                  productData.price,
                  productData.discountPercentage
                )}`}</div>
                <div className="line-through text-zcGrey-700">{`₹${productData.price?.toFixed(
                  2
                )}`}</div>
              </div>

              <div className="flex gap-2 items-center text-sm">
                {productData.averageRating && (
                  <RatingCard rating={productData.averageRating?.toFixed(2)} />
                )}
                {shouldShowReviewsComponent && (
                  <InfoBubble info={`${productData.numberOfReviews} ratings`} />
                )}
              </div>
            </div>
            <Divider />

            <div className="flex gap-4 items-center">
              <Button
                colorScheme="brand"
                borderRadius="full"
                height="55px"
                width="250px"
                onClick={onButtonClick}
              >
                {isItemInCart ? "Go to Cart" : "Add to Cart"}
              </Button>
            </div>

            {productData.longDescription && <Divider />}

            {productData.longDescription && (
              <div className="flex flex-col gap-4">
                <div className="font-bold text-xl">Product Description</div>
                <div className="text-zcGrey-700">
                  {productData.longDescription}
                </div>
              </div>
            )}

            {shouldShowReviewsComponent && <Divider />}

            {shouldShowReviewsComponent && (
              <ProductReviews productId={productId} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
