import axios from "axios";

const genRanHex = (size) =>
  [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");

const getTraceParent = () => {
  // Generate trace ID and span ID
  const traceId = genRanHex(32);
  const spanId = genRanHex(16);

  // Construct the TraceParent header value
  const traceParentValue = `00-${traceId}-${spanId}-01`;

  return traceParentValue;
};

const fetcher = axios.create();

fetcher.interceptors.request.use(
  async (config) => {
    if (!navigator.onLine) return Promise.reject("No internet connection");

    const token = localStorage.getItem("token");

    const traceParentValue = getTraceParent();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    config.headers["traceparent"] = traceParentValue;

    // Add Auth headers and other common headers here
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default fetcher;
