const COLOR_PALLETTE = {
  brand: {
    100: "#C5ECE1",
    300: "#45C09F",
    500: "#00A278",
    700: "#00845C",
  },
  zcGrey: {
    50: "#ffffff",
    100: "#fafafa",
    200: "#f5f5f5",
    300: "#f0f0f0",
    400: "#dedede",
    500: "#c2c2c2",
    600: "#979797",
    700: "#818181",
    800: "#606060",
    900: "#3c3c3c",
  },
  zcAlert: {
    100: "#FFD2DD",
    200: "#E35477",
    300: "#D75951",
  },
  zcYellow: {
    100: "#FBF3EA",
    200: "#FDB022",
    300: "#D79000",
  },
  zcGreen: {
    100: "#006D00",
    200: "#43A418",
    300: "#6AC82B",
    400: "#9AD972",
    500: "#EEF9E7",
  },
};

module.exports = COLOR_PALLETTE;
