import { getExperimentConfig } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const paymentController = {
  createPayment(payload) {
    const url = `${config[baseURLIdentifiers.ORDER_SERVICE]}/api/v1/payment`;
    return fetcher.post(url, payload, getExperimentConfig());
  },
  patchPayment(id, payload) {
    const url = `${
      config[baseURLIdentifiers.ORDER_SERVICE]
    }/api/v1/payment/${id}`;
    return fetcher.patch(url, payload, getExperimentConfig());
  },
};
