import { Button } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate('/')
  };

  return (
    <div className="flex flex-col h-full items-center justify-center gap-4">
      <div className="text-3xl font-bold">404</div>
      <div>The page you are looking for, does not exist</div>
      <Button
        colorScheme="brand"
        rounded="full"
        paddingX="2.5rem"
        paddingY="1.5rem"
        onClick={onButtonClick}
      >
        {"Go to Home"}
      </Button>
    </div>
  );
}
