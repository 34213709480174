import { useQuery } from "react-query";
import { productController } from "../../controllers/product-controller";
import { QUERY_KEYS } from "../../constants/queryKeys";

export default function useRecommendedProducts() {
  const { GET_RECOMMENDED_PRODUCTS } = QUERY_KEYS;

  const getRecommendedProducts = async () => {
    const products = await productController.getRecommendedProducts();
    return products.data;
  };

  const recommendedProducts = useQuery(
    [GET_RECOMMENDED_PRODUCTS],
    getRecommendedProducts,
    {
      keepPreviousData: true,
      staleTime: 300000,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
    }
  );

  return recommendedProducts;
}
