import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EExperiments } from "../../constants/experiments";
import { ELocalStorageType } from "../../constants/general";
import { SET_EXPERIMENTS, SET_EXPERIMENT_VALUE } from "../../constants/types";

const ExperimentDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const dispatch = useDispatch();

  const experimentsReducer = useSelector((state) => state.experimentsReducer);

  const { experiments } = experimentsReducer;

  useEffect(() => {
    const savedExperiments = localStorage.getItem(
      ELocalStorageType.EXPERIMENTS
    );

    if (savedExperiments) {
      dispatch({
        type: SET_EXPERIMENTS,
        payload: JSON.parse(savedExperiments),
      });
    }
  }, []);

  const enableExperimentHandler = (key, value) => {
    dispatch({
      type: SET_EXPERIMENT_VALUE,
      payload: {
        key,
        value,
      },
    });

    localStorage.setItem(
      ELocalStorageType.EXPERIMENTS,
      JSON.stringify({ ...experiments, [key]: value })
    );
  };

  const getIsChecked = (key) => {
    return experiments[key];
  };

  return (
    <div className="fixed right-8 bottom-8">
      <Button
        colorScheme="brand"
        rounded="full"
        paddingX="2.5rem"
        paddingY="1.5rem"
        className="flex gap-2"
        onClick={onOpen}
      >
        <FontAwesomeIcon icon="sliders" />
        Settings
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton _focus={{ boxShadow: "none" }} />
          <DrawerHeader>Experiments</DrawerHeader>

          <DrawerBody className="flex flex-col gap-4">
            {EExperiments.map((el) => (
              <div
                className="flex justify-between items-center font-semibold shadow-sm p-4 rounded-lg border"
                key={el}
              >
                <h3>{el.title}</h3>
                <Switch
                  _focus={{ boxShadow: "none" }}
                  id={el.id}
                  isChecked={getIsChecked(el.id)}
                  onChange={(e) => {
                    enableExperimentHandler(
                      el.id,
                      e.target.checked ? {} : null
                    );
                  }}
                />
              </div>
            ))}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default ExperimentDrawer;
