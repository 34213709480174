import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function AlertChip({ type, message, icon }) {
  const getIcon = () => {
    switch (type) {
      case "success":
        return <FontAwesomeIcon icon="check" />;
      case "error":
        return <FontAwesomeIcon icon="circle-exclamation" />;
      case "warn":
        return <FontAwesomeIcon icon="triangle-exclamation" />;
      default:
        return <></>;
    }
  };

  const getClassName = () => {
    switch (type) {
      case "success":
        return "text-brand-300";
      case "error":
        return "text-zcAlert-200";
      case "warn":
        return "text-zcYellow-300";
      default:
        return "text-zcGrey-800";
    }
  };

  return (
    <div
      className={`flex items-center gap-2 px-4 w-fit py-1 text-sm rounded-full bg-zcGrey-200 font-semibold ${getClassName()}`}
    >
      {icon ? icon : getIcon()}
      {message}
    </div>
  );
}
