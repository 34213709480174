import { useQuery, useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { reviewController } from "../../controllers/review-controller";

export default function useReviews(itemId, reviewPage) {
  const { GET_REVIEWS } = QUERY_KEYS;
  const queryClient = useQueryClient();

  const getReviews = async (itemId, page) => {
    const reviews = await reviewController.getReviews(itemId, page);
    return reviews.data;
  };

  const reviewsQuery = useQuery(
    [GET_REVIEWS, itemId, reviewPage],
    () => getReviews(itemId, reviewPage),
    {
      keepPreviousData: true,
      staleTime: 15000,
      refetchOnWindowFocus: false,
      enabled: !!itemId,
    }
  );

  const prefetchReviews = (page) => {
    const data = queryClient.getQueryData([GET_REVIEWS, itemId, page]);
    if (!data) {
      queryClient.prefetchQuery([GET_REVIEWS, itemId, page], () =>
        getReviews(itemId, page)
      );
    }
  };

  const getReviewsTotalPages = () => {
    return reviewsQuery.data?.data?.totalPages;
  };

  return { prefetchReviews, reviewsQuery, getReviewsTotalPages };
}
