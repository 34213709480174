import { Button, useToast } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormField from "../../components/FormField";
import routes from "../../constants/routes";
import { AuthContext } from "../../helpers/auth";

const ERegisterFormField = {
  name: "name",
  email: "email",
  password: "password",
};

const RegisterPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const { signup, authenticate } = useContext(AuthContext);

  const onSubmit = (values) => {
    const email = values.email.trim();
    const password = values.password.trim();

    signup(email, password, values.name)
      .then((data) => {
        authenticate(email, password).then((data) => {
          toast({
            description: (
              <div className="max-w-[300px] text-center text-sm">
                Logged In Successfully!
              </div>
            ),
            status: "success",
            position: "top",
            duration: 2000,
          });
          navigate(routes.HOME);
        });
      })
      .catch((err) => {
        toast({
          description: (
            <div className="max-w-[300px] text-center text-sm">
              {err.message}
            </div>
          ),
          status: "error",
          position: "top",
          duration: 2000,
        });
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [ERegisterFormField.name]: "",
      [ERegisterFormField.email]: "",
      [ERegisterFormField.password]: "",
    },
  });

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <form
          className="shadow-md p-4 rounded-lg flex flex-col gap-2 justify-cetner"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className="text-xl font-bold text-center">Sign In</h1>

          <FormField
            name={ERegisterFormField.name}
            errors={errors}
            type="text"
            label="Name"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            }}
            className="w-full md:min-w-[350px]"
          />

          <FormField
            name={ERegisterFormField.email}
            errors={errors}
            type="email"
            label="Email Address"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 4, message: "Minimum length should be 4" },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            className="w-full md:min-w-[350px]"
          />

          <FormField
            name={ERegisterFormField.password}
            errors={errors}
            label="Password"
            type="password"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 4, message: "Minimum length should be 4" },
            }}
            className="w-full md:min-w-[350px]"
          />

          <Button
            mt={4}
            // isLoading={isSubmitting}
            type="submit"
            _focus={{ boxShadow: "none" }}
            colorScheme="brand"
            className="mt-4"
          >
            Register
          </Button>
        </form>
      </div>
    </>
  );
};

export default RegisterPage;
