import { Button, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormField from "../../components/FormField";
import routes from "../../constants/routes";
import useWebhook from "../../hooks/webhook/useWebhook";
import FormFieldCheckbox from "../../components/FormFieldCheckbox";

const EWHRegisterFormField = {
  callbackUrl: "callbackUrl",
  apiKey: "apiKey",
  triggers: "triggers"
};

const WebhookRegistrationPage = () => {
  const { triggersQuery, prefetchTriggers, saveWebhookMutation } = useWebhook();
  const toast = useToast();
  const navigate = useNavigate();
  
  useEffect(() => {
    prefetchTriggers()
  }, [prefetchTriggers]);

  const triggerList = triggersQuery?.data?.data?.data || [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      [EWHRegisterFormField.callbackUrl]: "",
      [EWHRegisterFormField.apiKey]: "",
      [EWHRegisterFormField.triggers]: [],
    },
  });

  const onSubmit = (values) => {
    console.log(values);
    saveWebhookMutation.mutateAsync(values)
    .then(
      (val) => {
        toast({
          description: "Webhook registered successfully",
          status: "success",
          duration: 3000,
          isClosable: false,
        });
        navigate(routes.WEBHOOK_LOGS);
      }
    ).catch(
      (e) => {
        if (e.response.status === 400) {
          setError(EWHRegisterFormField.callbackUrl, {
            message: e.response.data.message
          })
        }
      }
    );
  };

  const goToWebhookLogPage = () => {
    navigate(routes.WEBHOOK_LOGS);
  };
    

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <form
          className="shadow-md p-4 rounded-lg flex flex-col gap-2 justify-cetner"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className="text-xl font-bold text-center">Register Webhook</h1>

          <FormField
            name={EWHRegisterFormField.callbackUrl}
            errors={errors}
            type="text"
            label="Callback URL"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            }}
            className="w-full md:min-w-[350px]"
          />

          <FormField
            name={EWHRegisterFormField.apiKey}
            errors={errors}
            type="text"
            label="API Key"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 3, message: "Minimum length should be 3" },
            }}
            className="w-full md:min-w-[350px]"
          />

          <FormFieldCheckbox
            name={EWHRegisterFormField.triggers}
            label="Triggers"
            errors={errors}
            options={triggerList}
            register={register}
            className="w-full"
          />

          <Button
            mt={4}
            type="submit"
            _focus={{ boxShadow: "none" }}
            colorScheme="brand"
            className="mt-4"
          >
            Register
          </Button>

          <Text py={2}>
            Already registered? 
            <Button mx={2} onClick={goToWebhookLogPage}>
              Check logs
            </Button>
          </Text>
        </form>        
      </div>
    </>
  );
};

export default WebhookRegistrationPage;
