import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import React from "react";
import CardPayment from "./CardPayment";
import UPI from "./UPI";
import CashOnDelivery from "./CashOnDelivery";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CARD_DETAILS,
  SET_PAYMENT_METHOD,
  SET_VPA,
} from "../../../constants/types";
import AlertChip from "../../AlertChip";
import { EPaymentType } from "../../../constants/product";

export default function Payment() {
  const dispatch = useDispatch();

  const orderReducer = useSelector((state) => state.orderReducer);
  const { vpa, cardDetails, paymentMethod } = orderReducer;

  const getAlertChipMessage = () => {
    if (!paymentMethod) return "No Payment method selected";

    if (paymentMethod === EPaymentType.UPI && !vpa)
      return "Please provide UPI ID to proceed";

    if (paymentMethod === EPaymentType.DEBIT_CARD && !cardDetails)
      return "Please provide card details to proceed";

    return null;
  };

  const updatePaymentMethodHandler = (method) => {
    dispatch({
      type: SET_PAYMENT_METHOD,
      payload: method,
    });
  };

  const updateUPIDetails = (upi) => {
    dispatch({
      type: SET_VPA,
      payload: upi,
    });
  };

  const updateCardDetails = (payload) => {
    dispatch({
      type: SET_CARD_DETAILS,
      payload,
    });
  };

  return (
    <AccordionItem>
      {({ isExpanded }) => (
        <div>
          <AccordionButton
            padding="24px 0"
            _hover="none"
            _focus={{ boxShadow: "none" }}
          >
            <div className="flex md:flex-row flex-col gap-4 justify-between w-full">
              <div className="font-bold text-left">
                <span>2.&nbsp;</span>
                <span className="underline">Payment Method</span>
              </div>

              {getAlertChipMessage() && (
                <AlertChip type="error" message={getAlertChipMessage()} />
              )}
            </div>
          </AccordionButton>
          <AccordionPanel>
            <Accordion className="bg-zcGrey-300 border border-zcGrey-400 p-4 xl:w-[800px] rounded-2xl">
              <CardPayment
                updatePaymentMethodHandler={updatePaymentMethodHandler}
                updateCardDetails={updateCardDetails}
              />
              <UPI
                updatePaymentMethodHandler={updatePaymentMethodHandler}
                updateUPIDetails={updateUPIDetails}
              />
              <CashOnDelivery
                updatePaymentMethodHandler={updatePaymentMethodHandler}
              />
            </Accordion>
          </AccordionPanel>
        </div>
      )}
    </AccordionItem>
  );
}
