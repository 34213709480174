export const QUERY_KEYS = {
    SEARCH_PRODUCTS: 'searchProducts',
    GET_RECOMMENDED_PRODUCTS: 'getRecommendedProducts', 
    GET_PRODUCT: 'getProduct',
    GET_ORDERS: 'getOrders',
    GET_ORDER: 'getOrder',
    GET_CART: "GET_CART",
    GET_REVIEWS: "GET_REVIEWS",
    GET_WH_TRIGGERS: "GET_WH_TRIGGERS",
    SAVE_WEBHOOK: "SAVE_WEBHOOK",
    GET_WH_LOGS: "GET_WH_LOGS",
};
