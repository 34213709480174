const routes = {
  HOME: "/",
  CART: "/cart",
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  PRODUCT: "/product",
  CHECKOUT: "/checkout",
  ORDER_HISTORY: "/order-history",
  THANKS: "/thanks",
  WEBHOOK_REG: "/webhook/register",
  WEBHOOK_LOGS: "/webhook",
};

export default routes;
