import { useQuery } from "react-query";
import { productController } from "../../controllers/product-controller";
import { QUERY_KEYS } from "../../constants/queryKeys";

export default function useProduct(productId) {
  const { GET_PRODUCT } = QUERY_KEYS;

  const getProduct = async (productId) => {
    const products = await productController.getProductById(productId);
    return products.data;
  };

  const product = useQuery(
    [GET_PRODUCT, productId],
    () => getProduct(productId),
    {
      keepPreviousData: true,
      staleTime: 50000,
    }
  );

  return product;
}
