import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  CircularProgress,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import Pagination from "../../Pagination";
import useOrders from "../../../hooks/orders/useOrders";
import { format } from "date-fns";
import { SET_ORDER_PAGE } from "../../../constants/types";
import { useDispatch } from "react-redux";
import NotFound from "../../Products/NotFound";
import { useNavigate } from "react-router-dom";
import OrderDetail from "./OrderDetail";
import OrderMeta from "./OrderMeta";
import OrderHeader from "./OrderHeader";
import routes from "../../../constants/routes";
import { startCase, toLower } from "lodash";

export default function OrderHistory() {
  const { orders: ordersHook, getOrdersTotalPages } = useOrders();
  const ordersData = ordersHook?.data?.content;
  const hasOrders = Array.isArray(ordersData) && ordersData.length > 0;
  const isLoading = ordersHook.isLoading;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const totalPages = getOrdersTotalPages();

  const handlePageClick = (event) => {
    dispatch({
      type: SET_ORDER_PAGE,
      payload: event.selected,
    });
  };

  const getOrderHistoryUI = () => {
    if (isLoading)
      return (
        <div className="flex  items-center justify-center h-full">
          <CircularProgress
            color="green.400"
            isIndeterminate
            trackColor="white"
            thickness="6px"
          />
        </div>
      );

    if (!hasOrders)
      return (
        <NotFound
          buttonText={"Shop Now"}
          onButtonClick={() => {
            navigate(routes.HOME);
          }}
          message={<div>No Orders History Found</div>}
        />
      );

    return (
      <Accordion
        width="full"
        className="flex flex-col gap-6 mb-10"
        allowMultiple
      >
        {ordersData?.map((order) => {
          return (
            <AccordionItem
              key={`order-${order.id}`}
              borderX="1px solid #DEDEDE"
              borderBottom="1px solid #DEDEDE"
              borderRadius="8px"
            >
              {({ isExpanded }) => (
                <div className="flex flex-col gap-2">
                  <AccordionButton
                    _focus={{ boxShadow: "none" }}
                    bgColor="#F0F0F0"
                    width="full"
                    borderColor="#DEDEDE"
                    borderTopRadius="8px"
                  >
                    <OrderHeader
                      createdAt={order.createdAt}
                      amount={order.amount}
                      id={order.id}
                    />
                  </AccordionButton>

                  <AccordionPanel className="flex flex-col gap-4">
                    <OrderMeta
                      address={order.shippingAddress}
                      paymentMethod={
                        Array.isArray(order.paymentList) &&
                        order.paymentList.length > 0
                          ? startCase(toLower(order.paymentList[0].paymentType))
                          : "Cash On Delivery"
                      }
                    />
                  </AccordionPanel>

                  <OrderDetail orderData={order}/>
                </div>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex md:items-center flex-start justify-between md:flex-row flex-col gap-3">
        <div className="font-bold text-xl">Order History</div>
        {totalPages > 1 && (
          <Pagination
            handlePageClick={handlePageClick}
            totalPages={totalPages}
            pageRangeDisplayed={3}
          />
        )}
      </div>
      <Divider />

      {getOrderHistoryUI()}
    </div>
  );
}
