import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getTruncatedString } from "../../helpers/general";

const OrderSummaryCard = ({
  items,
  onClick,
  buttonText,
  isDisabled = false,
}) => {
  const total = items
    ?.reduce((acc, data) => acc + data.item.price * data.quantity, 0)
    ?.toFixed(2);

  return (
    <div className="flex-1">
      <div className="w-full lg:w-[450px] p-8 bg-white rounded-[10px] shadow border border-zinc-100 flex-col justify-start items-start">
        <div className="text-yellow-600 text-xl lg:text-[26px] font-semibold tracking-tight mb-4">
          Order summary
        </div>
        <div className="flex-col gap-4 flex w-full">
          <div className="w-full flex-col justify-start items-start gap-4 flex text-neutral-500 text-base font-normal leading-normal">
            {items.map((data) => (
              <div
                className="flex justify-between w-full gap-2 text-sm"
                key={data.item.itemId}
              >
                <div className="flex-1 font-medium">
                  <span className="line-clamp-2">{getTruncatedString(data.item.title, 40)}</span> <br />
                  <FontAwesomeIcon icon="times" size="sm" /> {data.quantity}{" "}
                </div>
                <div className="w-[20%]">₹ {data.item.price?.toFixed(2)}</div>
                <div className="w-[20%] text-right">
                  ₹ {(data.item.price * data.quantity)?.toFixed(2)}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-[1px] bg-gray-200"></div>
          <div className="flex justify-between w-full text-neutral-700 text-lg font-semibold leading-normal">
            <div className="">Total</div>
            <div className="">₹ {total}</div>
          </div>
          <div className="text-center w-full mt-4">
            <Button
              _focus={{ boxShadow: "none" }}
              colorScheme="brand"
              isDisabled={isDisabled}
              className="!rounded-full !px-12 !h-[50px] !w-full"
              onClick={() => {
                onClick();
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummaryCard;
