import COLOR_PALLETTE from './colorPallette'
const { extendTheme } = require('@chakra-ui/react');

const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },

    Text: {
      defaultProps: {
        colorScheme: 'primary',
      },
    },
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
  colors: { ...COLOR_PALLETTE },
});

export default theme;
