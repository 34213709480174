import { useMutation } from "react-query";
import { MUTATION_KEYS } from "../../constants/mutationKeys";
import { paymentController } from "../../controllers/payment-controller";
import { EPaymentType } from "../../constants/product";
import { useSelector } from "react-redux";

export default function useCreatePayment() {
  const { MUTATION_KEY_CREATE_PAYMENT } = MUTATION_KEYS;
  const { userData } = useSelector((state) => state.authReducer);
  const email = userData?.email;

  const createPayment = async (orderId, paymentType) => {
    const payload = {
        userId: email,
        orderId: orderId,
        paymentType: paymentType,
        paymentGateway: paymentType === EPaymentType.CASH_ON_DELIVERY ? "NONE" : "STRIPE",
    };
    const payment = await paymentController.createPayment(payload);
    return payment.data;
  };

  const payment = useMutation(
    [MUTATION_KEY_CREATE_PAYMENT],
    ({orderId, paymentType}) => createPayment(orderId, paymentType),
  );

  return payment;
}
