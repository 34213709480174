import { SET_USER_DATA, SET_TOKEN } from "../constants/types";

const initialState = {
  userData: {},
  refreshToken: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    case "SET_REFRESH_TOKEN":
      return {
        ...state,
        refreshToken: action.payload,
      };

    default:
      return state;
  }
};
