import React, { useEffect } from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
} from "@chakra-ui/react";
import FormField from "../../FormField";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SET_ADDRESS_DETAILS } from "../../../constants/types";
import AlertChip from "../../AlertChip";
import { useToast } from '@chakra-ui/react'

const ADDRESS_KEY = "addressDetails";

const EaddressPaymentField = {
  fullAddress: "fullAddress",
  pinCode: "pinCode",
  city: "city",
  state: "state",
  country: "country",
};

export default function Delivery() {
  const dispatch = useDispatch();
  const toast = useToast()

  const orderReducer = useSelector((state) => state.orderReducer);
  const { addressDetails } = orderReducer;

  const updateAddressHandler = (address) => {
    dispatch({
      type: SET_ADDRESS_DETAILS,
      payload: address,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (addressDetails) {
      setValue("fullAddress", addressDetails.fullAddress);
      setValue("pinCode", addressDetails.pinCode);
      setValue("city", addressDetails.city);
      setValue("state", addressDetails.state);
      setValue("country", addressDetails.country);
    }
  }, [setValue]);

  const onSubmit = (data) => {
    updateAddressHandler(data);
    localStorage.setItem(ADDRESS_KEY, JSON.stringify(data));
    toast({
      description: "Address added successfully",
      status: 'success',
      duration: 3000,
      isClosable: false,
    })
  };

  return (
    <AccordionItem borderTop="none">
      {({ isExpanded }) => (
        <div>
          <AccordionButton
            padding="24px 0"
            _hover="none"
            _focus={{ boxShadow: "none" }}
          >
            <div className="flex justify-between w-full md:flex-row flex-col gap-3 items-start">
              <div className="font-bold text-left">
                <span>1.&nbsp;</span>
                <span className="underline">Delivery Address</span>
              </div>

              {
                (getValues("fullAddress") && !isExpanded) && (
                  <div className="flex gap-2 items-center">
                    <div className="text-sm text-right max-w-[320px] w-full text-zcGrey-700">
                      {getValues("fullAddress")}
                    </div>
                    <Button size="sm" variant="ghost" color="#00A278">
                      Edit
                    </Button>
                  </div>
                )
              }

              {
                !getValues("fullAddress") && <AlertChip type="error" message="No address details found" />
              }


            </div>
          </AccordionButton>
          <AccordionPanel>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4 xl:w-[800px]"
            >
              <FormField
                name={EaddressPaymentField.fullAddress}
                errors={errors}
                register={register}
                validations={{
                  required: "This is required",
                  minLength: {
                    value: 10,
                    message: "Minimum length should be 10",
                  },
                }}
                inputProps={{
                  placeholder: "Full Address",
                  backgroundColor: "white",
                  borderRadius: "full",
                  border: "1px solid #3C3C3C",
                }}
                errorMessageProps={{
                  fontSize: "xs",
                }}
              />

              <div className="flex gap-4">
                <div className="flex-1">
                  <FormField
                    name={EaddressPaymentField.pinCode}
                    errors={errors}
                    register={register}
                    validations={{
                      required: "This is required",
                    }}
                    inputProps={{
                      placeholder: "Pin Code",
                      backgroundColor: "white",
                      borderRadius: "full",
                      border: "1px solid #3C3C3C",
                    }}
                    errorMessageProps={{
                      fontSize: "xs",
                    }}
                  />
                </div>
                <div className="flex-1">
                  <FormField
                    name={EaddressPaymentField.city}
                    errors={errors}
                    register={register}
                    validations={{
                      required: "This is required",
                    }}
                    inputProps={{
                      placeholder: "City",
                      backgroundColor: "white",
                      borderRadius: "full",
                      border: "1px solid #3C3C3C",
                    }}
                    errorMessageProps={{
                      fontSize: "xs",
                    }}
                  />
                </div>
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <FormField
                    name={EaddressPaymentField.state}
                    errors={errors}
                    register={register}
                    validations={{
                      required: "This is required",
                    }}
                    inputProps={{
                      placeholder: "State",
                      backgroundColor: "white",
                      borderRadius: "full",
                      border: "1px solid #3C3C3C",
                    }}
                    errorMessageProps={{
                      fontSize: "xs",
                    }}
                  />
                </div>
                <div className="flex-1">
                  <FormField
                    name={EaddressPaymentField.country}
                    errors={errors}
                    register={register}
                    validations={{
                      required: "This is required",
                    }}
                    inputProps={{
                      placeholder: "Country",
                      backgroundColor: "white",
                      borderRadius: "full",
                      border: "1px solid #3C3C3C",
                    }}
                    errorMessageProps={{
                      fontSize: "xs",
                    }}
                  />
                </div>
              </div>

              <Button
                width="full"
                colorScheme="brand"
                borderRadius="full"
                height="50px"
                type="submit"
                disabled={!isValid}
              >
                Save Address
              </Button>
            </form>
          </AccordionPanel>
        </div>
      )}
    </AccordionItem>
  );
}
