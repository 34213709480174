import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React from "react";

export default function FormField({
  name,
  validations,
  register,
  label,
  type,
  errors,
  className,
  inputProps,
  errorMessageProps
}) {
  return (
    <FormControl isInvalid={errors[name]} className={className}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Input
        {...register(name, validations)}
        type={type}
        _focus={{ boxShadow: "none" }}
        {...inputProps}
      />
      <FormErrorMessage {...errorMessageProps}>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  );
}
