import { getExperimentConfig } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const reviewController = {
  getReviews(itemId, page, size) {
    const url = `${config[baseURLIdentifiers.PRODUCT_SERVICE]}/api/v1/review`;

    // Prepare the query parameters
    const queryParams = {};
    queryParams.itemId = itemId;
    queryParams.page = page || 0;
    queryParams.size = size || 3;

    // Append the query parameters to the URL
    const queryString = new URLSearchParams(queryParams).toString();
    const requestUrl = `${url}${queryString && `?${queryString}`}`;

    return fetcher.get(requestUrl, getExperimentConfig());
  },
};
