import {
  SET_ORDER_PAGE,
  SET_PAYMENT_METHOD,
  SET_CARD_DETAILS,
  SET_ADDRESS_DETAILS,
  SET_VPA,
} from "../constants/types";

const initialState = {
  orderPage: 0,
  paymentMethod: null,
  vpa: localStorage.getItem("vpa"),
  cardDetails: JSON.parse(localStorage.getItem("cardDetails")),
  addressDetails: JSON.parse(localStorage.getItem("addressDetails")) || null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_PAGE:
      return {
        ...state,
        orderPage: action.payload,
      };

    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case SET_VPA:
      return {
        ...state,
        vpa: action.payload,
      };

    case SET_CARD_DETAILS:
      return {
        ...state,
        cardDetails: action.payload,
      };

    case SET_ADDRESS_DETAILS:
      return {
        ...state,
        addressDetails: action.payload,
      };

    default:
      return state;
  }
};
