import React from "react";
import OTPInput from "react-otp-input";

export default function CustomOtpInput({ otp, setOtp }) {
  return (
    <div className="bg-zcGrey-200 px-4 py-2 flex-1 text-sm rounded-md">
      <OTPInput
        containerStyle={{
          display: "flex",
          alignItems: "center",
        }}
        inputStyle={{
          width: "2rem",
          height: "2rem",
          border: "1px solid #c2c2c2",
          borderRadius: "6px",
        }}
        value={otp}
        onChange={(value) => {
          const re = /^[0-9\b]+$/;
          if (value === "" || re.test(value)) {
            setOtp(value);
          }
        }}
        numInputs={4}
        renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
        renderInput={(props) => <input {...props} />}
      />
    </div>
  );
}
