import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import fallBackImage from "../../../assets/images/fallback-image.webp";
import useCart from "../../../hooks/useCart";
import useAuthHandler from "../../../hooks/utility/useAuthHandler";
import { useNavigate } from "react-router-dom";
import routes from "../../../constants/routes";

export default function Product({ product, onProductClick }) {
  const { updateCartAndInvalidateCache, cartContainsItem } = useCart();
  const { verifyLoginAndProceed } = useAuthHandler();
  const navigate = useNavigate();
  const isItemInCart = cartContainsItem(product.id);

  const imageOne =
    Array.isArray(product.imageUrls) &&
    product.imageUrls?.length > 0 &&
    product.imageUrls[0];

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const onAddToCart = (e) => {
    e.stopPropagation();
    if (isItemInCart) {
      verifyLoginAndProceed(() => navigate(routes.CART));
      return;
    }

    verifyLoginAndProceed(() => {
      updateCartAndInvalidateCache(product.id, 1);
    });
  };


  return (
    <div
      className="flex flex-col gap-2 min-w-[200px] max-w-[350px] cursor-pointer"
      onClick={() => {
        onProductClick(product);
      }}
    >
      <div className="w-full h-[220px] rounded-lg bg-zcGrey-100">
        <img
          src={!imageOne || imageError ? fallBackImage : imageOne}
          alt=""
          className="w-full h-full rounded-lg object-contain"
          onError={handleImageError}
        />
      </div>

      <div className="flex justify-between items-center gap-x-3">
        <p className="font-bold line-clamp-2 text-ellipsis truncate">
          {product.title}
        </p>

        <p>
          <span>₹&nbsp;</span>
          <span>{product.price}</span>
        </p>
      </div>

      <div className="flex items-center gap-2 mt-auto">
        <StarRatings
          rating={product.averageRating}
          starRatedColor="#FDB022"
          starEmptyColor="#dedede"
          numberOfStars={5}
          starSpacing="2px"
          starDimension="16px"
        />

        <div className="text-sm">{`(${product.numberOfReviews})`}</div>
      </div>

      <Button
        rounded="full"
        variant="outline"
        colorScheme="brand"
        width="min"
        color={"#00A278"}
        onClick={onAddToCart}
      >
        {isItemInCart ? "GO TO CART" : "ADD TO CART"}
      </Button>
    </div>
  );
}
