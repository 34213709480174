import { AccordionIcon } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";

export default function OrderHeader({ createdAt, amount, id }) {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex gap-4">
        <div className="flex flex-col gap-1">
          <div>Order Placed</div>
          <div className="text-sm">
            {format(new Date(createdAt), "d MMMM yyyy")}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div>Total</div>
          <div className="text-sm">{`₹ ${amount?.toFixed(2)}`}</div>
        </div>
      </div>

      <div className="flex gap-3">
        <div className="flex flex-col items-end">
          <div>Order #</div>
          <div className="font-bold text-sm">{id}</div>
        </div>
        <AccordionIcon />
      </div>
    </div>
  );
}
