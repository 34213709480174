const EExperiments = [
  {
    id: "experiment_001",
    title: "Experiment 1",
  },
  {
    id: "experiment_002",
    title: "Synchronous Click Stream",
  },
  {
    id: "experiment_003",
    title: "Non-Idempotent Orders",
  },
];

module.exports = {
  EExperiments,
};
