import React from "react";
import CustomRadio from "../../../CustomRadio";
import { AccordionButton, AccordionItem } from "@chakra-ui/react";
import { EPaymentType } from "../../../../constants/product";

export default function CashOnDelivery({ updatePaymentMethodHandler }) {
  return (
    <AccordionItem borderBottom="none">
      {({ isExpanded }) => (
        <div>
          <AccordionButton
            padding="24px 0"
            _hover="none"
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              updatePaymentMethodHandler(EPaymentType.CASH_ON_DELIVERY);
            }}
          >
            <CustomRadio radioText="Cash On Delivery" isChecked={isExpanded} />
          </AccordionButton>
        </div>
      )}
    </AccordionItem>
  );
}
