import React from "react";
import useProducts from "../../../hooks/products/useProducts";
import { useDispatch } from "react-redux";
import { SET_PRODUCT_PAGE } from "../../../constants/types";
import Pagination from "../../Pagination";
import { useMediaQuery } from "@chakra-ui/react";

export default function ProductsPagination() {
  const { getProductsTotalPages } = useProducts();
  const dispatch = useDispatch();

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false, 
  })

  const totalPages = getProductsTotalPages();

  const handlePageClick = (event) => {
    dispatch({
      type: SET_PRODUCT_PAGE,
      payload: event.selected,
    });
    // Scroll to top smoothly
    window.scrollTo({
      top: isLargerThan800 ? 350 : 400,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {totalPages > 1 && (
        <Pagination
          handlePageClick={handlePageClick}
          totalPages={totalPages}
          pageRangeDisplayed={3}
        />
      )}
    </div>
  );
}
