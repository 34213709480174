import { getExperimentConfig } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const productController = {
  searchProducts(searchKeyword, sortBy, page) {
    const url = `${
      config[baseURLIdentifiers.PRODUCT_SERVICE]
    }/api/v1/item/search`;

    // Prepare the query parameters
    const queryParams = {};
    if (searchKeyword) queryParams.keyword = searchKeyword;

    if (sortBy) queryParams.sortBy = sortBy;

    queryParams.page = page || 0;

    // Append the query parameters to the URL
    const queryString = new URLSearchParams(queryParams).toString();
    const requestUrl = `${url}${queryString && `?${queryString}`}`;

    return fetcher.get(requestUrl, getExperimentConfig());
  },
  getProductById(productId) {
    const url = `${
      config[baseURLIdentifiers.PRODUCT_SERVICE]
    }/api/v1/item/${productId}`;
    return fetcher.get(url, getExperimentConfig());
  },
  getRecommendedProducts() {
    const url = `${
      config[baseURLIdentifiers.PRODUCT_SERVICE]
    }/api/v1/item/recommended`;
    return fetcher.get(url, getExperimentConfig());
  },
};
