import { getExperimentConfig, getActivFlags } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const orderController = {
  getOrders(page, size) {
    const url = `${config[baseURLIdentifiers.ORDER_SERVICE]}/api/v1/order/all`;

    // Prepare the query parameters
    const queryParams = {};

    queryParams.page = page || 0;
    queryParams.size = size || 10;

    // Append the query parameters to the URL
    const queryString = new URLSearchParams(queryParams).toString();
    const requestUrl = `${url}${queryString && `?${queryString}`}`;

    return fetcher.get(requestUrl, getExperimentConfig());
  },
  getOrder(orderId) {
    const url = `${
      config[baseURLIdentifiers.ORDER_SERVICE]
    }/api/v1/order/${orderId}`;

    const queryParams = {};

    // Append the query parameters to the URL
    const queryString = new URLSearchParams(queryParams).toString();
    const requestUrl = `${url}${queryString && `?${queryString}`}`;

    return fetcher.get(requestUrl, getExperimentConfig());
  },
  saveOrder(orderDetails) {
    const cartData = orderDetails.cartData;
    const payload = orderDetails.payload;
    const url = `${config[baseURLIdentifiers.ORDER_SERVICE]}/api/v1/order`;
    let configs = getExperimentConfig();

    const flags = getActivFlags();
    if (!flags["experiment_003"]) {
      const requestId = JSON.stringify({
        updatedAt: cartData.data.updatedAt,
        userId: cartData.data.userId,
        version: cartData.data.version
      });

      configs.headers['Idempotency-Token'] = btoa(requestId);
    }

    return fetcher.post(url, payload, configs);
  },
};
