import { useToast } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { orderController } from "../../controllers/order-controller";

export default function useSaveOrder() {
  const toast = useToast();

  const saveOrderMutation = useMutation(
    ["SAVE_ORDER"],
    (payload) => orderController.saveOrder(payload),
    {
      onError: (e) => {
        toast({
          description: (e.response.status === 409) ? e.response.data.message : "Failed to order",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
      },
    }
  );

  return saveOrderMutation;
}
