export const SET_PRODUCT_SEARCH = "PRODUCT_SEARCH";
export const SET_PRODUCT_SORT_BY = "PRODUCT_SORT_BY";
export const SET_PRODUCT_PAGE = "PRODUCT_SORT_PAGE";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_TOKEN = "SET_TOKEN";
export const SET_PRODUCT_PAGE_SIZE = "PRODUCT_SORT_PAGE_SIZE";

export const SET_ORDER_PAGE = "ORDER_PAGE";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_VPA = "SET_VPA";
export const SET_CARD_DETAILS = "SET_CARD_DETAILS";
export const SET_ADDRESS_DETAILS = "SET_ADDRESS_DETAILS";

export const SET_EXPERIMENTS = "SET_EXPERIMENTS";
export const SET_EXPERIMENT_VALUE = "SET_EXPERIMENT_VALUE";
