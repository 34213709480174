import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { webhookController } from "../../controllers/webhook-controller";
import { useToast } from "@chakra-ui/react";

export default function useWebhook() {
    const { GET_WH_TRIGGERS, SAVE_WEBHOOK, GET_WH_LOGS } = QUERY_KEYS;
    const queryClient = useQueryClient();
    const toast = useToast();

    const getTriggers = async () => {
        const triggerList = await webhookController.getTriggers();
        return triggerList;
    };

    const triggersQuery = useQuery(
        [GET_WH_TRIGGERS],
        () => getTriggers(),
        {
            keepPreviousData: true,
            staleTime: 15000,
            refetchOnWindowFocus: false,
        }
    );

    const prefetchTriggers = () => {
        const data = queryClient.getQueryData([GET_WH_TRIGGERS]);
        if (!data) {
            queryClient.prefetchQuery([GET_WH_TRIGGERS], () =>
                getTriggers()
            );
        }
    };

    const getWebhookLogs = async () => {
        const logs = await webhookController.getWebhookLogs();
        return logs;
    };

    const whLogsQuery = useQuery(
        [GET_WH_LOGS],
        () => getWebhookLogs(),
        {
            keepPreviousData: true,
            staleTime: 15000,
            refetchOnWindowFocus: false,
        }
    );

    const prefetchWhLogs = () => {
        const data = queryClient.getQueryData([GET_WH_LOGS]);
        if (!data) {
            queryClient.prefetchQuery([GET_WH_LOGS], () =>
                getWebhookLogs()
            );
        }
    };

    const saveWebhookMutation = useMutation(
        [SAVE_WEBHOOK],
        (payload) => webhookController.addWebhook(payload),
        {
            onError: (e) => {
                toast({
                    description: "Failed to register webhook",
                    status: "error",
                    duration: 3000,
                    isClosable: false,
                });
            },
        }
    );

    return { triggersQuery, prefetchTriggers, saveWebhookMutation, whLogsQuery, prefetchWhLogs };
}