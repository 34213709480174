import { SET_EXPERIMENTS, SET_EXPERIMENT_VALUE } from "../constants/types";

const initialState = {
  experiments: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPERIMENTS:
      return {
        ...state,
        experiments: action.payload,
      };

    case SET_EXPERIMENT_VALUE:
      return {
        ...state,
        experiments: {
          ...state.experiments,
          [action.payload.key]: action.payload.value,
        },
      };

    default:
      return state;
  }
};
