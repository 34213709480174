import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import useCart from "../../hooks/useCart";
import ImageComponent from "../ImageComponent";
import ProductQuantity from "../ProductQuantity";
import { useToast } from "@chakra-ui/react";

const OrderCard = ({ item, quantity, isReadOnly }) => {
  const { price, imageUrls, title } = item || {};
  const image = imageUrls?.[0];
  const [value, setValue] = useState(quantity);
  const toast = useToast();

  const onAddToCartError = (error) => {
    const errorMessage = error.response.data.message;
    if (errorMessage.startsWith("inventory exhausted")) {
      toast({
        description: <div>Inventory Exhausted</div>,
        status: "error",
        duration: 2000,
      });
    }
    setValue(quantity)
  };

  const { updateCart } = useCart(onAddToCartError);

  const debouncedHandleIncrease = useRef(
    debounce((quantity) => updateCart(item.id, quantity), 500)
  ).current;

  const handleAddToCart = (quantity) => {
    debouncedHandleIncrease(quantity);
  };

  return (
    <div className="w-full justify-start items-start gap-8 flex flex-col lg:flex-row">
      <div className="rounded-xl h-[180px] w-full lg:w-[220px] lg:h-[220px]">
        <ImageComponent
          image={image || ""}
          className="rounded-xl object-contain w-full h-full"
        />
      </div>

      <div className="flex-col justify-start items-start flex-1 gap-2 flex">
        <div className="text-neutral-700 text-sm md:text-base font-medium">
          {title}
        </div>

        <div className="justify-start items-center gap-4 inline-flex">
          <div className="text-right">
            <span className="text-neutral-700 text-xl font-medium">
              ₹{price}
            </span>
          </div>
        </div>

        {isReadOnly ? (
          <></>
        ) : (
          <div className="justify-start items-center gap-6 inline-flex">
            <div className="text-center text-emerald-600 text-lg font-black">
              <FontAwesomeIcon
                icon="trash"
                onClick={() => {
                  updateCart(item.id, 0);
                }}
                className="cursor-pointer"
              />
            </div>
            <ProductQuantity
              value={value}
              setValue={setValue}
              onDecrease={(quantity) => {
                handleAddToCart(quantity);
              }}
              onIncrease={(quantity) => {
                handleAddToCart(quantity);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderCard;
