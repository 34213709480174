import { useMutation } from "react-query";
import { paymentController } from "../../controllers/payment-controller";
import { MUTATION_KEYS } from "../../constants/mutationKeys";

const randomIntFromInterval = (min = 1, max = Number.MAX_SAFE_INTEGER) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default function usePatchPayment() {
  const { MUTATION_KEY_PATCH_PAYMENT } = MUTATION_KEYS;

  const createPayment = async (paymentId) => {
    const payload = {
      transactionId: randomIntFromInterval(),
      paymentStatus: "INITIATED",
    };
    const payment = await paymentController.patchPayment(paymentId, payload);
    return payment.data;
  };

  const payment = useMutation([MUTATION_KEY_PATCH_PAYMENT], (paymentId) =>
    createPayment(paymentId)
  );

  return payment;
}
