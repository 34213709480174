import { getExperimentConfig } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const webhookController = {
    addWebhook(webhookRequest) {
        const url = `${
            config[baseURLIdentifiers.USER_SERVICE]
          }/api/v1/webhook`;

        return fetcher.post(url, webhookRequest, getExperimentConfig());            
    },

    getTriggers() {
        const url = `${config[baseURLIdentifiers.USER_SERVICE]}/api/v1/webhook/trigger`;
        return fetcher.get(url, getExperimentConfig());
    },

    getWebhookLogs() {
        const url = `${config[baseURLIdentifiers.USER_SERVICE]}/api/v1/webhook/log`;
        return fetcher.get(url, getExperimentConfig());
    }
};