import React, { useCallback, useRef, useState } from "react";
import ImageComponent from "../../ImageComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProductImageCarousel({ images }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const currentImage = images[currentImageIndex];
  const shouldShowImageCarousel = Array.isArray(images) && images.length > 1;

  const onImageClick = (idx) => setCurrentImageIndex(idx);

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <ImageComponent
        className="md:h-[400px] h-[300px] max-w-[400px] w-full rounded-lg"
        image={currentImage}
      />
      {shouldShowImageCarousel && (
        <div className="flex items-center gap-3">
          <FontAwesomeIcon
            icon="chevron-left"
            onClick={handlePrev}
            className="cursor-pointer"
          />
          <Swiper
            ref={sliderRef}
            slidesPerView={"auto"}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            className="h-full w-full"
          >
            {images?.map((image, idx) => (
              <SwiperSlide
                key={`image-product-${idx}`}
                className={`w-[115px] h-[115px] ${
                  idx === currentImageIndex &&
                  "border-[3px] border-rounded rounded-xl border-brand-300"
                }`}
              >
                <ImageComponent
                  className={`w-full h-full rounded-lg cursor-pointer`}
                  image={image}
                  onClick={() => onImageClick(idx)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <FontAwesomeIcon
            icon="chevron-right"
            onClick={handleNext}
            className="cursor-pointer"
          />
        </div>
      )}
    </div>
  );
}
