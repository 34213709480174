import { CircularProgress } from "@chakra-ui/react";
import React from "react";

export default function ProductsLoader() {
  return (
    <div className="flex items-center justify-center h-full">
      <CircularProgress color="green.400" isIndeterminate trackColor="white" thickness="6px"/>
    </div>
  );
}
