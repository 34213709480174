import React, { useEffect, useState } from "react";
import useReviews from "../../../hooks/reviews/useReviews";
import Review from "./Review";
import { Divider } from "@chakra-ui/react";
import Pagination from "../../Pagination";

export default function ProductReviews({ productId }) {
  const [currentPage, setCurrentPage] = useState(0);

  const { prefetchReviews, reviewsQuery, getReviewsTotalPages } = useReviews(
    productId,
    currentPage
  );

  useEffect(() => {
    prefetchReviews(currentPage + 1);
  }, [currentPage, prefetchReviews]);

  const reviewData = reviewsQuery?.data?.data?.content;
  const totalPages = getReviewsTotalPages();
  const handlePageClick = (e) => {
    setCurrentPage(e.selected);
  };

  return Array.isArray(reviewData) && reviewData.length > 0 ? (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
        <div className="font-bold text-xl">Reviews</div>
        <Pagination
          size="sm"
          handlePageClick={handlePageClick}
          totalPages={totalPages}
          pageRangeDisplayed={3}
        />
      </div>
      <div className="flex flex-col gap-4">
        {reviewData?.map((review) => (
          <div className="gap-4 flex flex-col" key={`review-${review.id}`}>
            <Review review={review} />
            <Divider />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
}
