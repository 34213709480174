import { sortBy } from "lodash-es";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { cartController } from "../controllers/cart-controller";
import { MUTATION_KEYS } from "../constants/mutationKeys";
import { QUERY_KEYS } from "../constants/queryKeys";
import { useCallback } from "react";

export default function useCart(onAddToCartError = () => {}) {
  const { GET_CART } = QUERY_KEYS;

  const { MUTATION_KEYS_ADD_TO_CART } = MUTATION_KEYS;

  const queryClient = useQueryClient();

  const { userData } = useSelector((state) => state.authReducer);

  const email = userData?.email;

  const getCart = async () => {
    const cart = await cartController.getCart();
    return cart.data;
  };

  const cartQuery = useQuery(
    [GET_CART, email],
    () =>
      getCart(email).then((res) => {
        const data = {
          ...res,
          data: {
            ...res.data,
            availableItem: sortBy(res.data.availableItem, "item.id"),
          },
        };

        return data;
      }),
    {
      enabled: Boolean(email),
      retry: 0,
      staleTime: 5000,
      cacheTime: 5000,
      refetchOnWindowFocus: true,
    }
  );

  const addToCartMutation = useMutation(
    [MUTATION_KEYS_ADD_TO_CART, email],
    ({ payload }) => cartController.addToCart(payload).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData([GET_CART, email], (oldData) => {
          if (!oldData) return oldData;

          const updatedData = data.data.itemQuantityList;

          const filteredData = {
            ...oldData,
            data: {
              ...oldData.data,
              availableItem: sortBy(
                updatedData
                  .map((item) => {
                    const temp = oldData.data.availableItem.find(
                      (el) => el.item.id === item?.itemId
                    );

                    return temp
                      ? { item: temp.item, quantity: item.quantity }
                      : false;
                  })
                  .filter(Boolean),
                "item.id"
              ),
            },
          };

          return filteredData;
        });
      },
      onError: (error) => {
        onAddToCartError(error);
      },
    }
  );

  const updateCart = async (itemId, quantity, onSuccess = () => {}) => {
    try {
      await addToCartMutation.mutateAsync({
        payload: {
          itemId,
          quantity,
        },
      });
      onSuccess();
    } catch (error) {
      console.log(error);
    }
  };

  const updateCartAndInvalidateCache = async (
    itemId,
    quantity,
    onSuccess = () => {}
  ) => {
    await addToCartMutation.mutateAsync({
      payload: {
        itemId,
        quantity,
      },
    });

    invalidateCartCache();

    onSuccess();
  };

  const invalidateCartCache = () => {
    queryClient.invalidateQueries([GET_CART, email]);
  };

  const removeCartQuery = () => {
    queryClient.removeQueries([GET_CART, email]);
  };

  const cartData = queryClient.getQueryData([GET_CART, email]);

  const cartContainsItem = useCallback(
    (itemId) => {
      if (!cartData) return false;
      const item = cartData?.data.availableItem.find(
        (item) => item.item.id === Number(itemId)
      );
      return Boolean(item);
    },
    [cartData]
  );

  return {
    cartQuery,
    cartData,
    addToCartMutation,
    updateCart,
    updateCartAndInvalidateCache,
    invalidateCartCache,
    removeCartQuery,
    cartContainsItem,
  };
}
