import React from "react";
import useOrder from "../../../../hooks/orders/useOrder";
import ImageComponent from "../../../ImageComponent";
import { CircularProgress } from "@chakra-ui/react";

export default function OrderDetail({ orderData, id }) {
  // const orderHook = useOrder(id);
  // const orderData = orderHook?.data?.data;
  const isLoading = false;

  if (isLoading)
    return (
      <div className="flex items-center gap-3 justify-center h-full">
        <CircularProgress
          color="green.400"
          isIndeterminate
          trackColor="white"
          thickness="6px"
          size="24px"
        />
        <div className="text-sm">Loading Order Items...</div>
      </div>
    );

  const openProduct = (id) => {
    // open in new tab
    window.open(`/product/${id}`, "_blank");
  };

  return (
    <div className="flex flex-col gap-2 px-10">
      {orderData?.itemDetailsQuantity.map((itemDetail, idx) => {
        return (
          <div
            onClick={() => {
              openProduct(itemDetail.item.id);
            }}
            key={`item-${idx}-${itemDetail.item.id}`}
            className={`pb-2 cursor-pointer ${
              idx !== orderData.itemDetailsQuantity.length - 1 &&
              "border-b-[1px] border-zcGrey-400"
            }`}
          >
            <div className="justify-start items-start gap-6 flex md:flex-row flex-col w-full">
              {Array.isArray(itemDetail.item.imageUrls) &&
                itemDetail.item.imageUrls.length > 0 && (
                  <div className="rounded-xl h-[140px] w-full md:w-[180px] md:h-[180px]">
                    <ImageComponent
                      image={itemDetail.item.imageUrls[0] || ""}
                      className="rounded-xl object-contain w-full h-full"
                    />
                  </div>
                )}

              <div className="flex-col justify-start items-start gap-2 inline-flex md:flex-1">
                <div className="text-neutral-700 font-medium">
                  {itemDetail.item.title}
                </div>
                <div>₹{itemDetail.item.price}</div>
                <div className="text-sm">Quantity: {itemDetail.quantity}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
