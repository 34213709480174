import { getExperimentConfig } from "../../api/experimentHelper";
import fetcher from "../../api/fetcher";
import { config } from "../../config";
import { baseURLIdentifiers } from "../../constants/baseURLIdentifiers";

export const cartController = {
  getCart() {
    const url = `${
      config[baseURLIdentifiers.ORDER_SERVICE]
    }/api/v1/cart/view`;

    return fetcher.get(url, getExperimentConfig());
  },
  addToCart(payload) {
    const url = `${
      config[baseURLIdentifiers.ORDER_SERVICE]
    }/api/v1/cart`;

    return fetcher.patch(url, payload, getExperimentConfig());
  },
  deleteCartById(id) {
    const url = `${config[baseURLIdentifiers.ORDER_SERVICE]}/api/v1/cart/${id}`;

    return fetcher.delete(url, getExperimentConfig());
  },
};
