import React from "react";

export default function OrderMeta({ address, paymentMethod }) {
  return (
    <div className="flex gap-4 justify-between mt-2 rounded-lg bg-zcGrey-300 py-4 px-6 items-start">
      <div className="flex flex-col gap-2">
        <div className="font-bold">Shipping Address</div>
        <div className="max-w-[350px] text-sm">
          {address ||
            "175 & 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar, Bengaluru, Karnataka 560076"}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="font-bold">Payment Method</div>
        <div className="text-sm">{paymentMethod}</div>
      </div>
    </div>
  );
}
