import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import store from "./store";
import RootLayout from "./layouts/RootLayout";
import "./index.css";
import "tailwindcss/tailwind.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { ReactQueryDevtools } from 'react-query/devtools'

import { library } from "@fortawesome/fontawesome-svg-core";
import { Auth } from "./helpers/auth";
import {
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faStar,
  faShareFromSquare,
  faPlus,
  faMinus,
  faCartShopping,
  faTrash,
  faTimes,
  faCircleExclamation,
  faTriangleExclamation,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faStar,
  faShareFromSquare,
  faPlus,
  faMinus,
  faCartShopping,
  faTrash,
  faTimes,
  faCircleExclamation,
  faTriangleExclamation,
  faCheck
);

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ChakraProvider theme={theme}>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              preventDuplicate
            >
              <Auth>
                <RootLayout>
                  <App />
                </RootLayout>
              </Auth>
            </SnackbarProvider>
          </ChakraProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,

  document.getElementById("root")
);
