import { ELocalStorageType } from "../constants/general";

const removeObjectNullFields = (obj) => {
  const newObj = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

export const getExperimentConfig = () => {
  let experiments = JSON.parse(
    localStorage.getItem(ELocalStorageType.EXPERIMENTS)
  );

  experiments = JSON.stringify(removeObjectNullFields(experiments));

  const config = {
    headers: { baggage: `experiment_flag=${window.btoa(experiments)}` },
  };

  return config;
};

export const getActivFlags = () => {
  let experiments = JSON.parse(
    localStorage.getItem(ELocalStorageType.EXPERIMENTS)
  );

  return removeObjectNullFields(experiments);
}
