import { InputRightAddon, InputGroup, Input, useMediaQuery } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { SET_PRODUCT_SEARCH } from "../../constants/types";

export default function SearchInput({ placeholder }) {
  const [value, setValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const dispatch = useDispatch();

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false,
  })

  const handleChange = (event) => setValue(event.target.value);

  const debouncedHandleChange = debounce((inputValue) => {
    setDebouncedValue(inputValue);
  }, 500);

  useEffect(() => {
    debouncedHandleChange(value);
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [value]);

  useEffect(() => {
    dispatch({
      type: SET_PRODUCT_SEARCH,
      payload: debouncedValue,
    });
  }, [debouncedValue]);



  return (
    <div>
      <InputGroup size={isLargerThan800 ? "md" : "sm"}>
        <Input
          colorScheme="brand"
          borderColor={"#00A278"}
          _focus={{ boxShadow: "none" }}
          _hover={{ borderColor: "#00A278" }}
          placeholder={placeholder ? placeholder : "Search an Item"}
          value={value}
          onChange={handleChange}
          size={isLargerThan800 ? "md" : "sm"}
        />
        <InputRightAddon
          bgColor={"#00A278"}
          borderColor={"#00A278"}
          onClick={() => {
            if (value) {
              setValue("");
            }
          }}
          className="cursor-pointer"
          children={
            value ? (
              <FontAwesomeIcon icon="times" className="text-white" size="sm" />
            ) : (
              <FontAwesomeIcon icon="magnifying-glass" className="text-white" size="sm"/>
            )
          }
        />
      </InputGroup>
    </div>
  );
}
