import { useQuery } from "react-query";
import { orderController } from "../../controllers/order-controller";
import { QUERY_KEYS } from "../../constants/queryKeys";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export default function useOrders() {
  const { GET_ORDERS } = QUERY_KEYS;
  const orderReducer = useSelector((state) => state.orderReducer);
  const authReducer = useSelector((state) => state.authReducer);

  const orderPage = orderReducer.orderPage;
  const userId = authReducer.userData.email;

  const getOrders = async (page, size) => {
    const products = await orderController.getOrders(page, size);
    return products.data;
  };

  const orders = useQuery([GET_ORDERS, orderPage], () => getOrders(orderPage), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 10000,
    enabled: !!userId,
  });

  const getOrdersTotalPages = useCallback(() => {
    return orders.data?.data?.totalPages;
  }, [orders.data?.data?.totalPages]);

  return { orders, getOrdersTotalPages };
}
