import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { useToast } from "@chakra-ui/react";
import authenticatedRoutes from "../../constants/routes/authenticatedRoutes";

export default function useAuthHandler() {
  const isLoggedIn = localStorage.getItem("token");

  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  const redirectToLogin = useCallback(() => {
    navigate(routes.LOGIN);
  }, [navigate]);

  const failCallback = useCallback(() => {
    toast({
      description: "Please login to continue",
      status: "warning",
      duration: 2000,
    });
    redirectToLogin();
  }, [redirectToLogin, toast]);

  const verifyLoginAndProceed = useCallback(
    (callback) => {
      if (isLoggedIn) callback();
      else failCallback();
    },
    [isLoggedIn, failCallback]
  );

  const verifyLoginForPageRedirect = useCallback(
    (callback) => {
      if (!isLoggedIn && authenticatedRoutes.includes(location.pathname))
        failCallback();
      else {
        if (callback && typeof callback === "function") callback();
      }
    },
    [isLoggedIn, failCallback, location.pathname]
  );

  return { verifyLoginAndProceed, verifyLoginForPageRedirect };
}
