import React from "react";
import { productSortKeys } from "../../../constants/product";
import Select from "react-select";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { SET_PRODUCT_SORT_BY } from "../../../constants/types";

export default function ProductSort() {
  const productReducer = useSelector((state) => state.productReducer);
  const sortBy = productReducer.productSortBy;
  const dispatch = useDispatch();

  const getSortOptionObject = (key) => {
    return {
      label: _.startCase(_.lowerCase(key)),
      value: key,
    };
  };

  const getOptions = () =>
    productSortKeys.map((key) => getSortOptionObject(key));

  const handleChange = (selectedOption) => {
    dispatch({
      type: SET_PRODUCT_SORT_BY,
      payload: selectedOption.value,
    });
  };

  return (
    <Select
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          width: "180px",
          boxShadow: "none",
          "&:hover": { borderColor: "#dedede" },
          border: "1px solid #F0F0F0",
          borderRadius: "100px",
          fontSize: "14px",
          cursor: "pointer",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          paddingLeft: "0",
          marginLeft: "0",
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isSelected
            ? "#C5ECE1"
            : state.isFocused
            ? "#f0f0f0"
            : "#fff",
          cursor: "pointer",
          fontWeight: state.isSelected ? "bold" : "",
          color: "#3C3C3C",
          fontSize: "14px",
        }),
        valueContainer: (provided) => ({
          ...provided,
          padding: "0 6px",
          textAlign: "center",
          margin: "0",
        }),
        input: (provided) => ({
          ...provided,
          width: "min-content",
          textAlign: "center",
          padding: "0",
          margin: "0",
          caretColor: "transparent",
        }),
      }}
      value={getSortOptionObject(sortBy)}
      onChange={handleChange}
      options={getOptions()}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
}
