import { Radio } from "@chakra-ui/react";
import React from "react";

export default function CustomRadio(props) {
  const { radioText, ...rest } = props;
  return (
    <Radio colorScheme="brand" _focus={{ boxShadow: "none" }} {...rest}>
      {radioText}
    </Radio>
  );
}
