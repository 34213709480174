import { Accordion } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Delivery from "./Delivery";
import Payment from "./Payment";
import CartCheckout from "./CartCheckout";
import OrderSummaryCard from "../OrderSummaryCard";
import useCart from "../../hooks/useCart";
import { useSelector } from "react-redux";
import useSaveOrder from "../../hooks/orders/useSaveOrder";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { EPaymentType } from "../../constants/product";
import PaymentModal from "./PaymentModal";

export default function Checkout() {
  const { cartData, removeCartQuery } = useCart();
  const availableItem = cartData?.data.availableItem;

  const navigate = useNavigate();

  const saveOrderMutation = useSaveOrder();

  const [currentOrderId, setCurrentOrderId] = useState(null);

  const orderReducer = useSelector((state) => state.orderReducer);
  const { vpa, cardDetails, paymentMethod, addressDetails } = orderReducer;
  const [isValid, setIsValid] = useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const onPayNowClick = async () => {
    setIsPaymentModalOpen(true);

    const res = await saveOrderMutation.mutateAsync({
      payload: {
        orderItemsDetail: availableItem.map((item) => ({
          itemId: item.item.id,
          quantity: item.quantity,
        })),
        paymentType: paymentMethod,
        shippingAddress: addressDetails.fullAddress,
        billingAddress: addressDetails.fullAddress,
      },
      cartData: cartData
    });

    const orderId = res.data?.data?.id;
    setCurrentOrderId(orderId);

    removeCartQuery();

    console.log(paymentMethod);
    if (paymentMethod === EPaymentType.CASH_ON_DELIVERY)
      navigate(routes.THANKS + `/${orderId}`);
    else setIsPaymentModalOpen(true);
  };

  useEffect(() => {
    let isValid = true;

    if (!paymentMethod) {
      isValid = false;
    }

    if (!availableItem) {
      isValid = false;
    }

    if (paymentMethod === EPaymentType.DEBIT_CARD && !cardDetails) {
      isValid = false;
    }

    if (!addressDetails) {
      isValid = false;
    }

    setIsValid(isValid);
  }, [vpa, cardDetails, paymentMethod, addressDetails, availableItem]);

  return (
    <div className="flex flex-col">
      <h2 className="text-black text-2xl font-semibold">Checkout</h2>

      <div className="flex items-start gap-6 flex-col lg:flex-row">
        <div className="flex flex-col gap-4 flex-1 w-full">
          <Accordion allowMultiple>
            <Delivery />
            <CartCheckout items={availableItem || []} />
            <Payment />
          </Accordion>
        </div>

        <div className="pb-6">
          <OrderSummaryCard
            items={availableItem || []}
            onClick={onPayNowClick}
            buttonText="Order Now"
            isDisabled={!isValid}
          />
          {currentOrderId && (
            <PaymentModal
              orderId={currentOrderId}
              isOpen={isPaymentModalOpen}
              onClose={closePaymentModal}
              paymentType={paymentMethod}
            />
          )}
        </div>
      </div>
    </div>
  );
}

/* {
  <div className="flex items-center justify-center h-full">
<NotFound
  message="No items added to cart"
  onButtonClick={() => navigate(routes.HOME)}
/>
</div> 
} */
