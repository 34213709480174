import React, { useEffect } from "react";
import CustomRadio from "../../../CustomRadio";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  useToast,
} from "@chakra-ui/react";
import FormField from "../../../FormField";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { EPaymentType } from "../../../../constants/product";

const CARD_KEY = "cardDetails";

const EcardPaymentField = {
  cardNumber: "cardNumber",
  expiryDate: "expiryDate",
  cvv: "cvv",
  cardHolderName: "cardHolderName",
};

export default function CardPayment({
  updatePaymentMethodHandler,
  updateCardDetails,
}) {
  const orderReducer = useSelector((state) => state.orderReducer);
  const { cardDetails } = orderReducer;
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    // const cardDetails = localStorage.getItem(CARD_KEY);
    if (cardDetails) {
      // updateCardDetails(cardDetails);
      // const parseCardDetails = JSON.parse(cardDetails);
      setValue("cardNumber", cardDetails.cardNumber);
      setValue("expiryDate", cardDetails.expiryDate);
      setValue("cvv", cardDetails.cvv);
      setValue("cardHolderName", cardDetails.cardHolderName);
    }
  }, [setValue]);

  const onSubmit = (data) => {
    updateCardDetails(data);
    localStorage.setItem(CARD_KEY, JSON.stringify(data));
    toast({
      description: "Added Card Details Successfully",
      status: 'success',
      duration: 3000,
      isClosable: false,
    })
  };

  return (
    <AccordionItem borderTop="none">
      {({ isExpanded }) => (
        <div>
          <AccordionButton
            padding="24px 0"
            _hover="none"
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              updatePaymentMethodHandler(EPaymentType.DEBIT_CARD);
            }}
          >
            <CustomRadio radioText="Credit/Debit Card" isChecked={isExpanded} />
          </AccordionButton>
          <AccordionPanel>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4"
            >
              <FormField
                name={EcardPaymentField.cardNumber}
                errors={errors}
                register={register}
                validations={{
                  required: "This is required",
                  pattern: {
                    value: /^[0-9]{16}$/,
                    message: "Please enter 16 digit card number",
                  },
                }}
                inputProps={{
                  placeholder: "Card Number",
                  backgroundColor: "white",
                  borderRadius: "full",
                  border: "1px solid #3C3C3C",
                }}
                errorMessageProps={{
                  fontSize: "xs",
                }}
              />

              <div className="flex gap-4">
                <div className="flex-1">
                  <FormField
                    name={EcardPaymentField.expiryDate}
                    errors={errors}
                    register={register}
                    validations={{
                      required: "This is required",
                    }}
                    inputProps={{
                      placeholder: "Expiry Date",
                      backgroundColor: "white",
                      borderRadius: "full",
                      border: "1px solid #3C3C3C",
                      type: "date",
                    }}
                    errorMessageProps={{
                      fontSize: "xs",
                    }}
                  />
                </div>
                <div className="flex-1">
                  <FormField
                    name={EcardPaymentField.cvv}
                    errors={errors}
                    register={register}
                    validations={{
                      required: "This is required",
                      pattern: {
                        value: /^[0-9]{3}$/,
                        message: "Please enter only numbers",
                      },
                    }}
                    inputProps={{
                      placeholder: "CVV",
                      backgroundColor: "white",
                      borderRadius: "full",
                      border: "1px solid #3C3C3C",
                    }}
                    errorMessageProps={{
                      fontSize: "xs",
                    }}
                  />
                </div>
              </div>

              <FormField
                name={EcardPaymentField.cardHolderName}
                errors={errors}
                register={register}
                validations={{
                  required: "This is required",
                  minLength: {
                    value: 3,
                    message: "Minimum length should be 2",
                  },
                }}
                inputProps={{
                  placeholder: "Card Holder Name",
                  backgroundColor: "white",
                  borderRadius: "full",
                  border: "1px solid #3C3C3C",
                }}
                errorMessageProps={{
                  fontSize: "xs",
                }}
              />

              <div className="text-sm">
                <span className="font-bold">DISCLAIMER: </span>
                <span>
                  All entered payment information is simulated and not stored or
                  used anywhere else in Crio.Do. Please do not enter any real
                  payment information.
                </span>
              </div>

              <Button
                width="full"
                colorScheme="brand"
                borderRadius="full"
                height="50px"
                type="submit"
                disabled={!isValid}
              >
                Add Card
              </Button>
            </form>
          </AccordionPanel>
        </div>
      )}
    </AccordionItem>
  );
}
