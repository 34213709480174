import React, { useContext, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import SearchInput from "../../components/SearchInput";
import { AuthContext } from "../../helpers/auth";
import { useLocation } from "react-router-dom";
import useCart from "../../hooks/useCart";
import ExperimentDrawer from "../../components/ExperimentDrawer";

export default function RootLayout({ children }) {
  const location = useLocation();
  const { getSession } = useContext(AuthContext);
  useCart();

  useEffect(() => {
    getSession()
      .then((data) => {})
      .catch((err) => {});
  }, [location.pathname]);

  const getCenterElement = () => {
    switch (location.pathname) {
      case "/":
        return <SearchInput />;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex flex-col w-full h-screen">
      <Header centerElement={getCenterElement()} />
      <main className="flex-auto mt-[90px] px-6 md:px-0 md:mx-auto container">
        {children}
      </main>
      <Footer />
      <ExperimentDrawer />
    </div>
  );
}
