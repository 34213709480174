import { Button, useToast } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import FormField from "../../components/FormField";
import routes from "../../constants/routes";
import { AuthContext } from "../../helpers/auth";

const ELoginFormField = {
  email: "email",
  password: "password",
};

const LoginPage = () => {
  const navigate =useNavigate();
  const toast = useToast();

  const { authenticate } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (values) => {
    authenticate(values.email, values.password)
      .then((data) => {
        toast({
          description: (
            <div className="max-w-[300px] text-center text-sm">
              Logged In Successfully!
            </div>
          ),
          status: "success",
          position: "top",
          duration: 2000,
        });
        navigate(routes.HOME);
      })
      .catch((err) => {
        toast({
          description: (
            <div className="max-w-[300px] text-center text-sm">
              {err.message}
            </div>
          ),
          status: "error",
          position: "top",
          duration: 2000,
        });
      });
  };

  return (
    <>
      <div className="flex justify-center items-center h-full">
        <form
          className="shadow-md p-4 rounded-lg flex flex-col gap-2 justify-cetner"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1 className="text-xl font-bold text-center">Sign In</h1>

          <FormField
            name={ELoginFormField.email}
            errors={errors}
            type="email"
            label="Email Address"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 4, message: "Minimum length should be 4" },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            }}
            className="w-full md:min-w-[350px]"
          />

          <FormField
            name={ELoginFormField.password}
            errors={errors}
            label="Password"
            type="password"
            register={register}
            validations={{
              required: "This is required",
              minLength: { value: 4, message: "Minimum length should be 4" },
            }}
            className="w-full md:min-w-[350px]"
          />

          <Button
            mt={4}
            // isLoading={isSubmitting}
            type="submit"
            _focus={{ boxShadow: "none" }}
            colorScheme="brand"
            className="mt-4"
          >
            Login
          </Button>
          <div className="flex justify-center text-sm mt-2">
            <Link to={routes.SIGNUP} className="underline text-blue-500">
              Sign Up
            </Link>
            {/* <Link to={"/forgot-password"} className="underline text-blue-500">
              Forgot Password
            </Link> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginPage;
