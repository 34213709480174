import { Button } from "@chakra-ui/react";
import React from "react";
import Lottie from "lottie-react";
import catInTheBox from "../../../assets/animations/cat-in-box.json";

export default function NotFound({
  onButtonClick,
  message,
  buttonText,
  hideButton,
}) {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-3">
      <div className="w-[250px] h-[250px]">
        <Lottie animationData={catInTheBox} loop={true} />
      </div>

      {message}

      {!hideButton && (
        <Button
          colorScheme="brand"
          rounded="full"
          paddingX="2.5rem"
          paddingY="1.5rem"
          onClick={onButtonClick}
        >
          {buttonText ? buttonText : "Go to Home"}
        </Button>
      )}
    </div>
  );
}
