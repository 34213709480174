import { combineReducers } from "redux";
import productReducer from "./productReducer";
import authReducer from "./authReducer";
import orderReducer from "./orderReducer";
import experimentsReducer from "./experimentsReducer";

const rootReducer = combineReducers({
  productReducer,
  authReducer,
  orderReducer,
  experimentsReducer,
});

export default rootReducer;
