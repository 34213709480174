import React, { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/virtual";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomBanner from "./CustomBanner";
import { clickstreamController } from "../../controllers/clickstream-controller";

const banners = [
  {
    title: '<div>Exclusive discounts for all<br/>first time customers</div>',
    backgroundColor: 'bg-green-100',
  },
  {
    title: '<div>Upto 60% off on new inventory<br/>for select customers</div>',
    backgroundColor: 'bg-violet-100',
  },
  {
    title: '<div>Extra flat 5% off & freebies from<br/>our banking partners</div>',
    backgroundColor: 'bg-blue-100',
  },
]

export default function BannerCarousel() {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    // send clickstream data
    let activity = clickstreamController.getClickstreamPayload("click");
    activity.element_class = "cursor-pointer";
    activity = clickstreamController.addAttributes(activity, 
      {"key": "section", "value": "homepage banner"},
      {"key": "action", "value": "prev arrow click"}
    );
    clickstreamController.addActivity(activity);
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    // send clickstream data
    let activity = clickstreamController.getClickstreamPayload("click");
    activity.element_class = "cursor-pointer";
    activity = clickstreamController.addAttributes(activity, 
      {"key": "section", "value": "homepage banner"},
      {"key": "action", "value": "next arrow click"}
    );
    clickstreamController.addActivity(activity);
  }, []);

  return (
    <div className="w-full flex gap-2 items-center justify-center">
      <FontAwesomeIcon icon="chevron-left" onClick={handlePrev} className="cursor-pointer" />
      <Swiper
        slidesPerView={1}
        loop
        ref={sliderRef}
        className="rounded-xl"
      >
        {banners.map((bannerData, index) => (
          <SwiperSlide key={index}>
           <CustomBanner {...bannerData}/>
          </SwiperSlide>
        ))}
      </Swiper>
      <FontAwesomeIcon icon="chevron-right" onClick={handleNext} className="cursor-pointer" />
    </div>
  );
}
